import React from 'react';
import styled from 'styled-components';

import { isDefined } from '../../../js/utils/variables';
import { Stack } from '../stack/stack';

type Props = {
  title: string;
  subText?: string;
  children: React.ReactNode;
};

const PageSection = (props: Props) => {
  return (
    <Component direction="column" {...props}>
      <Title direction="column">
        <TitleText>{props.title}</TitleText>
        {isDefined(props.subText) && <SubText>{props.subText}</SubText>}
      </Title>
      {props.children}
    </Component>
  );
};

const Component = styled(Stack)`
  width: 100%;

  padding: 1em;
`;

const Title = styled(Stack)`
  border-bottom: 2px solid ${(props) => props.theme.color.gray.medium};

  margin-bottom: 1em;
`;

const TitleText = styled.h3`
  color: ${(props) => props.theme.color.gray.darkest};
`;

const SubText = styled.h5`
  color: ${(props) => props.theme.color.gray.medium};
`;

export { PageSection };
