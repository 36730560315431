import React, { ReactNode, useCallback, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled, { useTheme } from 'styled-components';
import { Vector3 } from 'three';

import { GcpModel } from '../../../../../typings/api/skymap/rest/v0/.common';
import { isDefined } from '../../../../js/utils/variables';
import { DataTable, DataTableColumns, DataTableSortType } from '../../data-table/data-table';
import { Icon } from '../../icon/icon';
import { IconPanel } from '../../icon-panel/icon-panel';
import { OverlayLoader } from '../../overlay-loader/overlay-loader';
import { Stack } from '../../stack/stack';
import { TextBox } from '../../text-box/text-box';
import { GeodataContext } from '../geodata-state';
import {
  GeodataImageItemWithMarkers,
  GeodataMarkerStatusIcon,
  PlaceMarkersContext,
} from './place-markers-state';

const pageSize = 15;
type Item = {
  id: string;
  status: 'pinned' | 'unpinned';
  statusIcon: ReactNode;
  imageName: string;
  image: GeodataImageItemWithMarkers;
  gcpName: string;
  gcp: GcpModel;
  residualError: number;
};

const searchKeys = ['gcpName', 'imageName', 'status'] as const;

function filterItems(
  items: Item[],
  searchText: string,
  filterOnActiveGcp: boolean,
  gcp?: GcpModel,
): Item[] {
  const text = searchText.trim().toLowerCase();

  const filters: ((item: Item) => boolean)[] = [];

  if (isDefined(gcp) && filterOnActiveGcp) {
    filters.push((item: Item) => gcp.id === item.gcp.id);
  }

  if (text.length > 0) {
    filters.push((item: Item) =>
      searchKeys.some((x) => item[x]?.toLocaleLowerCase().includes(text)),
    );
  }

  return items.filter((item) => filters.every((filter) => filter(item)));
}

export const NoMarkersPanel = () => {
  const { t } = useTranslation();

  return (
    <NoGcpPanelStyle>
      <IconPanel
        header={{ icon: { icon: ['fad', 'magnifying-glass'], opacity: '50%', size: '4x' } }}
        responsiveness={{ compressed: false }}
      >
        {{
          title: t('markerList.noMarkers', { ns: 'cloudProcessing' }),
          info: t('markerList.noMarkersInfo', { ns: 'cloudProcessing' }),
        }}
      </IconPanel>
    </NoGcpPanelStyle>
  );
};

const NoGcpPanelStyle = styled.div`
  display: flex;
  padding: 1em;
`;

const columns: DataTableColumns<Item> = {
  statusIcon: {
    title: 'Status',
    alignment: 'center',
    width: '45px',
    sortableBy: { type: DataTableSortType.STRING, columnKey: 'status' },
    unfilterable: true,
  },
  imageName: {
    sortableBy: { type: DataTableSortType.STRING },
    unfilterable: true,
    title: 'Bild',
  },
  gcpName: {
    sortableBy: { type: DataTableSortType.STRING },
    unfilterable: true,
    title: 'GCP',
  },
  residualError: {
    sortableBy: { type: DataTableSortType.NUMBER },
    unfilterable: true,
    title: 'Error (m)',
    formatter: (value) => value.toFixed(3),
  },
};

export const MarkerList = () => {
  const { geodata } = useContext(GeodataContext);
  const {
    gcp,
    image,
    selectImage,
    selectGcp,
    imagesWithMarkers,
    refetchMarkers,
    setShowPlaceMarkersDialog,
    setSortedImageList,
  } = useContext(PlaceMarkersContext);
  const { t } = useTranslation();
  const theme = useTheme();

  const [items, setItems] = useState<Item[]>([]);
  const [searchText, setSearchText] = useState('');
  const [filteredItems, setFilteredItems] = useState<Item[]>([]);
  const [sortedItems, setSortedItems] = useState<Item[]>([]);
  const [filterOnActiveGcp, setFilterOnActiveGcp] = useState(false);

  const renderNoImagesPanel = items.length === 0;

  useEffect(() => {
    if (isDefined(geodata.gcpCollection?.points)) {
      const newItems: Item[] = imagesWithMarkers.flatMap((image) => {
        return image.markers.map((marker) => {
          const gcp = geodata.gcpCollection?.points.find((x) => x.id === marker.gcpId);
          return {
            id: marker.gcpId,
            gcp: gcp!,
            gcpName: gcp!.name,
            image: image,
            imageName: image.displayName,
            status: marker.pinned ? 'pinned' : 'unpinned',
            residualError: isDefined(marker.residualError)
              ? new Vector3(...marker.residualError).length()
              : 0,
            statusIcon: GeodataMarkerStatusIcon(marker.pinned ? 'PINNED' : 'ESTIMATED', theme),
          } satisfies Item;
        });
      });

      setItems(newItems);
    } else {
      setItems([]);
    }
  }, [geodata, theme, imagesWithMarkers]);

  useEffect(() => {
    setFilteredItems(filterItems(items, searchText, filterOnActiveGcp, gcp));
  }, [items, searchText, filterOnActiveGcp, gcp]);

  const highlightRow = useCallback(
    (item: Item) => item.gcp.id === gcp?.id && item.image.imageId === image?.imageId,
    [gcp, image],
  );

  const onRowClicked = useCallback(
    (item: Item) => {
      setSortedImageList(
        sortedItems
          .filter((x) => isDefined(x.image.imageId))
          .map((x) => {
            return {
              imageId: x.image.imageId!,
              gcpId: x.gcp.id,
            };
          }),
      );
      selectGcp(item.gcp);
      selectImage(item.image);
      setShowPlaceMarkersDialog(true);
    },
    [sortedItems, selectGcp, selectImage, setShowPlaceMarkersDialog, setSortedImageList],
  );

  return (
    <OverlayLoader visible={false}>
      <Stack spacing={1}>
        <Stack alignItems="center" direction="row" spacing={0.5}>
          <TextBox
            placeholder={t('search', { ns: 'common' })}
            value={searchText}
            onChange={(e) => {
              setSearchText(e.target.value);
            }}
          />
          <Icon
            color={filterOnActiveGcp ? theme.color.green : theme.color.gray.dark}
            fixedWidth={true}
            icon={['fad', 'filter']}
            title={t('markerList.filterOnActiveGcp', { ns: 'cloudProcessing' })}
            onClick={() => setFilterOnActiveGcp((oldValue) => !oldValue)}
            onHoverStyle={{ icon: ['fas', 'filter'] }}
          />
          <Icon
            color={theme.color.gray.dark}
            fixedWidth={true}
            icon={['fad', 'refresh']}
            title={t('refetch', { ns: 'common' })}
            onClick={() => refetchMarkers()}
            onHoverStyle={{ icon: ['fas', 'refresh'] }}
          />
        </Stack>

        {renderNoImagesPanel ? (
          <NoMarkersPanel />
        ) : (
          <DataTable
            columns={columns}
            fixedLayout={true}
            highlightRow={highlightRow}
            items={filteredItems}
            pageSize={pageSize}
            onRowClicked={onRowClicked}
            onSortingChanged={setSortedItems}
          />
        )}
      </Stack>
    </OverlayLoader>
  );
};
