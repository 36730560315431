import React from 'react';
import styled from 'styled-components';

import { SkyMapAxiosServiceFactory } from '../../../../../js/services/axios/skymap-axios-service-factory';
import { isDefined } from '../../../../../js/utils/variables';
import MicrosoftLogoImage from '../../../../../static/Microsoft_logo.svg';

type Props = {
  token?: string;
  disabled?: boolean;
};

const Component = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f2f2f2;
  font-size: 16px;
  font-weight: 600;
  padding: 12px 20px;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  transition: background-color 0.3s ease;

  width: 100%;

  &:hover {
    background-color: #e2e2e2;
  }

  &:active {
    background-color: #d2d2d2;
  }

  > svg {
    width: 20px;
    height: 20px;
    margin-right: 8px;
  }
`;

const MicrosoftEntraIdSignInButton = (props: Props) => {
  const redirectToMicrosortSignIn = () => {
    let redirectUrl: string | undefined = undefined;
    if (isDefined(props.token)) {
      const authV1Service = SkyMapAxiosServiceFactory.instance.createUserServiceV1();

      redirectUrl = authV1Service.getInitiateAcceptUserInvitationProcessThroughSso({
        query: { token: props.token },
      });
    } else {
      const authV1Service = SkyMapAxiosServiceFactory.instance.createAuthServiceV1();

      redirectUrl = authV1Service.getMicrosoftRedirectUrl({ query: {} });
    }
    if (isDefined(redirectUrl)) {
      window.location.href = redirectUrl;
    }
  };

  return (
    <Component disabled={props.disabled} type="button" onClick={redirectToMicrosortSignIn}>
      <MicrosoftLogoImage />
      Microsoft
    </Component>
  );
};

export { MicrosoftEntraIdSignInButton };
