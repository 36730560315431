import React from 'react';
import styled from 'styled-components';

import { nameof } from '../../utils/nameof';

interface Props {
  url: string;
  target?: React.HTMLAttributeAnchorTarget;
  children?: React.ReactNode;
}

const Hyperlink = React.forwardRef<HTMLAnchorElement, Props>((props: Props, ref) => {
  return (
    <Component href={props.url} ref={ref} target={props.target}>
      {props.children ?? props.url}
    </Component>
  );
});

Hyperlink.displayName = nameof({ Hyperlink });

const Component = styled.a`
  text-decoration: underline;
  word-break: break-all;
  width: fit-content;
  cursor: pointer;

  color: hsl(206, 96%, 35%);
  &:visited {
    color: hsl(206, 96%, 35%);
  }

  &:hover,
  &:active {
    color: #000;
    text-decoration: none;
  }

  &:hover {
    background-color: hsl(206, 96%, 90%);
  }

  &:active {
    background-color: hsl(206, 96%, 80%);
  }
`;

export { Hyperlink, Component as HyperlinkStyled };
