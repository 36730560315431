import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import { t } from '../../../js/utils/i18-next';
import { ProfileLoadingState, useProfileContext } from '../../state/profile-state';
import { Stack } from '../stack/stack';
import { AuthenticationAndSecurity } from './authentication-section';
import { PageProperty } from './page-property';
import { PageSection } from './page-section';
import { ProfileOverview } from './profile-overview';

const ProfilePage = () => {
  const { profile, state } = useProfileContext();

  return (
    <Component className="notranslate" direction="row" spacing={'2em'}>
      <StyledProfileOverview profile={profile} />
      <PageContent direction="column">
        <PageSection title={t('sections.general.title', { ns: 'profile' })}>
          <Stack direction="row">
            <PageProperty
              isLoading={state === ProfileLoadingState.Loading}
              title={t('firstName', { ns: 'profile' })}
              value={profile?.firstName}
            />
            <PageProperty
              isLoading={state === ProfileLoadingState.Loading}
              title={t('lastName', { ns: 'profile' })}
              value={profile?.lastName}
            />
          </Stack>
        </PageSection>
        <PageSection
          subText={t('sections.authentication.subText', { ns: 'profile' })}
          title={t('sections.authentication.title', { ns: 'profile' })}
        >
          <Stack direction="row">
            <AuthenticationAndSecurity profile={profile} />
          </Stack>
        </PageSection>
      </PageContent>
    </Component>
  );
};

ProfilePage.propTypes = {
  wrapWithProfileState: PropTypes.bool,
};

const Component = styled(Stack)`
  min-height: 50vh;
`;

const StyledProfileOverview = styled(ProfileOverview)`
  flex-basis: 16em;
  flex-grow: 0;
`;

const PageContent = styled(Stack)`
  flex-grow: 1;
`;

export { ProfilePage };
