import { useMutation, useQuery } from '@tanstack/react-query';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import _ from 'underscore';
import { v4, validate } from 'uuid';

import {
  CoordinateSystemModel,
  CountryCoordinateSystemModel,
  GeoidModel,
  GetCoordinateSystemListResponse,
} from '../../../../typings/api/skymap/rest/v1/.common';
import { publish, SubscriptionTopic } from '../../../js/messaging/pubsub';
import { SkyMapAxiosServiceFactory } from '../../../js/services/axios/skymap-axios-service-factory';
import { ProjectStore } from '../../../js/stores/project-store';
import { UserStore } from '../../../js/stores/user-store';
import { isDefined } from '../../../js/utils/variables';
import { useDialog } from '../../hooks/use-dialog';
import { useErrorHandling } from '../../hooks/use-error-handling';
import { Button, ButtonStyled } from '../button/button';
import { DropdownSuggestion } from '../dropdown-suggestion/dropdown-suggesion';
import { Hyperlink } from '../hyperlink/hyperlink';
import { InfoBox } from '../info-box/info-box';
import { LabelledContainer } from '../labelled-container/labelled-container';
import { OverlayLoader } from '../overlay-loader/overlay-loader';
import { Select } from '../select/select';
import { Stack } from '../stack/stack';
import { AddGeoidDialog } from './dialogs/add-geoid-dialog';
import { SectionTitle } from './styles/styles';

interface Country {
  id: string;
  name: string;
}

export type CoordinateSystemItem = {
  id: string;
  name: string;
  countryId: string | null;
  sourceName?: string;
  sourceUrl?: string;
  licenseName?: string;
  licenseUrl?: string;
};

const noGeoidId = v4();

/**
 * Option for displaying all coordinate systems and geoids regardless of country.
 */
const countryAll: Country = {
  id: 'all',
  name: 'Alla',
};

/**
 * Placeholder item for coordinate system or geoid.
 */
const placeholder: CoordinateSystemItem = {
  id: 'placeholder',
  countryId: null,
  name: 'Välj',
};

function getUniqueCountries(response: GetCoordinateSystemListResponse) {
  let fullCountryList: Country[] = response.data
    .filter((x) => x.countryCoordinateSystems?.length === 1)
    .map(mapCountry);
  fullCountryList = _.uniq(fullCountryList, (x) => x.id);
  fullCountryList.push(countryAll);

  return fullCountryList;
}

const mapGeoid = (model: GeoidModel): CoordinateSystemItem => {
  return {
    countryId: model.countryGeoids?.[0]?.countryId ?? null,
    id: model.id,
    name: model.countryGeoids?.[0]?.displayName ?? model.name,
    sourceName: model.sourceName,
    sourceUrl: model.sourceUrl,
    licenseName: model.licenseName,
    licenseUrl: model.licenseUrl,
  };
};

const mapCountry = (model: CoordinateSystemModel): Country => {
  return {
    id: model.countryCoordinateSystems![0].countryId,
    name: model.countryCoordinateSystems![0].country!.name,
  };
};

const mapCoordinateSystem = (model: CoordinateSystemModel): CoordinateSystemItem[] => {
  const getName = (ccs?: CountryCoordinateSystemModel) =>
    `${ccs?.displayName ?? model.name} (${model.authority ?? ''})`;

  const noCountryCrs: CoordinateSystemItem = {
    countryId: null,
    id: model.id,
    name: getName(),
  };

  if (!isDefined(model.countryCoordinateSystems) || model.countryCoordinateSystems.length === 0) {
    return [noCountryCrs];
  }

  return [
    noCountryCrs,
    ...model.countryCoordinateSystems.map((ccs) => {
      return {
        countryId: ccs.countryId,
        id: model.id,
        name: getName(ccs),
      };
    }),
  ];
};

async function getGeoidList() {
  const getGeoidsResponse = await SkyMapAxiosServiceFactory.instance
    .createGeoidServiceV1()
    .getGeoids({});

  return getGeoidsResponse.data.map(mapGeoid);
}

async function getCoordinateSystemsAndCountries() {
  const response = await SkyMapAxiosServiceFactory.instance
    .createCoordinateSystemServiceV1()
    .getCoordinateSystems({});

  const fullCountryList = getUniqueCountries(response);

  /**
   * A flat list of coordinate systems based on country.
   *
   * For example, if a coordinate system exists for 2 countries this list
   * will contain 3 entries that represents that coordinate system.
   * One for each country.
   */
  const fullCoordinateSystemList = response.data.flatMap(mapCoordinateSystem);

  return { fullCoordinateSystemList, fullCountryList };
}

/**
 * Gets the selected coordinate system based on what coordinate system set in the
 * project store.
 *
 * TODO: Add countryId to Project. Then when every project is associated with a country
 * we can preselect the correct country coordinate system based on that country id.
 *
 * For now. If a coordinate system is available for two or more countries we will select
 * the entry with { countryId: null }. This will ensure that no specific country is
 * preselected in the country dropdown in the component.
 */
function getSelectedCoordinateSystem(fullCoordinateSystemList: CoordinateSystemItem[]) {
  if (!isDefined(ProjectStore.instance.coordinateSystem)) {
    return undefined;
  }

  const matchingCoordinateSystems = fullCoordinateSystemList.filter(
    (x) => x.id === ProjectStore.instance.coordinateSystem?.id,
  );

  /**
   * If the coordinate system doesnt exist in the coordinate list then we append it.
   * This can for example happen when the organization admin has set a coordinate system
   * for project that the company admin does not have access to.
   */
  if (matchingCoordinateSystems?.length === 0) {
    const unknownCrs = mapCoordinateSystem(ProjectStore.instance.coordinateSystem)[0];
    fullCoordinateSystemList.push(unknownCrs);
    return unknownCrs;
  }

  /**
   * Note:
   * The list will always contain one item without countryId. i.e. { countryId: null }
   * Any other entries will have countryId set.
   */
  return matchingCoordinateSystems?.length === 2
    ? matchingCoordinateSystems.find((x) => isDefined(x.countryId))
    : matchingCoordinateSystems?.find((x) => !isDefined(x.countryId));
}

async function getCoordinateSystemsAndGeoids() {
  const [coordinateSystems, geoids] = await Promise.all([
    getCoordinateSystemsAndCountries(),
    getGeoidList(),
  ]);

  return {
    coordinateSystems: coordinateSystems.fullCoordinateSystemList,
    countries: coordinateSystems.fullCountryList,
    geoids,
  };
}

export function useCoordinateSystemsAndGeoidsQuery() {
  const { handleError, hasError, clearErrors, buildErrorList } = useErrorHandling();
  const [queryId] = useState(UserStore.instance.user.id);

  const { data, refetch, error, isLoading } = useQuery({
    queryKey: ['getCoordinateSystemsAndGeoids', queryId],
    queryFn: getCoordinateSystemsAndGeoids,
    networkMode: 'always',
    retry: false,
    refetchInterval: false,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
  });

  // Define effect on error outside of mutation to avoid infinite render loop.
  useEffect(() => {
    if (isDefined(error)) {
      handleError(error, { retryCallback: refetch });
    } else {
      clearErrors();
    }
  }, [handleError, clearErrors, error, refetch]);

  const errorList = useMemo(() => buildErrorList(), [buildErrorList]);

  return {
    data,
    errorList,
    hasError,
    isLoading,
  };
}

const filterByCountry = (
  items: CoordinateSystemItem[],
  selectedItem: CoordinateSystemItem,
  countryId?: string,
) => {
  const filteredResult =
    countryId && countryId !== countryAll.id
      ? items.filter((x) => x.countryId === countryId)
      : items.filter((x) => !isDefined(x.countryId));

  if (!filteredResult.find((x) => x.id === selectedItem.id)) {
    filteredResult.unshift(selectedItem);
  }

  return filteredResult;
};

function usePutCoordinateSystemMutation() {
  const { t } = useTranslation();
  const { mutateAsync, isPending } = useMutation({
    mutationFn: (coordinateSystemId: string) => {
      return ProjectStore.instance.setCoordinateSystem(coordinateSystemId);
    },
    onSuccess: () => {
      publish(SubscriptionTopic.ToastrMessage, {
        type: 'success',
        message: t('projectSettings.projection.putCoordinateSystemSuccessMessage', {
          ns: 'components',
        }),
      });
    },
    onError: () => {
      publish(SubscriptionTopic.ToastrMessage, {
        type: 'error',
        message: t('projectSettings.projection.putCoordinateSystemErrorMessage', {
          ns: 'components',
        }),
      });
    },
    retry: false,
    networkMode: 'always',
  });

  const onCoordinateSystemChanged = useCallback(
    async (
      item: CoordinateSystemItem,
      setCoordinateSystem: (value: CoordinateSystemItem | undefined) => void,
    ) => {
      try {
        if (validate(item?.id) && item.id !== ProjectStore.instance.coordinateSystem?.id) {
          await mutateAsync(item.id);
        }
      } finally {
        setCoordinateSystem(item);
      }
    },
    [mutateAsync],
  );

  return {
    onCoordinateSystemChanged,
    isPendingPutCoordinateSystem: isPending,
  };
}

function usePutGeoidMutation() {
  const { t } = useTranslation();
  const { mutateAsync, isPending } = useMutation({
    mutationFn: (geoidId: string) => {
      return ProjectStore.instance.setGeoid(geoidId === noGeoidId ? null : geoidId);
    },
    onSuccess: () => {
      publish(SubscriptionTopic.ToastrMessage, {
        type: 'success',
        message: t('projectSettings.projection.putGeoidSuccessMessage', { ns: 'components' }),
      });
    },
    onError: () => {
      publish(SubscriptionTopic.ToastrMessage, {
        type: 'error',
        message: t('projectSettings.projection.putGeoidErrorMessage', { ns: 'components' }),
      });
    },
    retry: false,
    networkMode: 'always',
  });

  const onGeoidChanged = useCallback(
    async (
      item: CoordinateSystemItem,
      setGeoid: (value: CoordinateSystemItem | undefined) => void,
    ) => {
      try {
        if (validate(item?.id) && item.id !== ProjectStore.instance.geoid?.id) {
          await mutateAsync(item.id);
        }
      } finally {
        setGeoid(item);
      }
    },
    [mutateAsync],
  );

  return {
    onGeoidChanged,
    isPendingPutGeoid: isPending,
  };
}

const GeoidInfo = ({ geoid }: { geoid?: GeoidModel }) => {
  const { t } = useTranslation();
  const items = useMemo(() => {
    const result: {
      title: string;
      url: string;
      name: string;
    }[] = [];

    // Source.
    if (isDefined(geoid?.sourceName) && isDefined(geoid?.sourceUrl)) {
      result.push({
        title: t('projectSettings.projection.geoidModelSource', { ns: 'components' }),
        url: geoid.sourceUrl,
        name: geoid.sourceName,
      });
    }

    // Licence.
    if (isDefined(geoid?.licenseName) && isDefined(geoid?.licenseUrl)) {
      result.push({
        title: t('projectSettings.projection.geoidModelLicense', { ns: 'components' }),
        url: geoid.licenseUrl,
        name: geoid.licenseName,
      });
    }

    return result;
  }, [geoid, t]);

  if (items.length === 0) {
    return null;
  }

  return (
    <InfoBox color="yellow" topMargin={true}>
      <table>
        {items.map((item) => (
          <tr key={item.title}>
            <td>{item.title}</td>
            <td>
              <Hyperlink target="_blank" url={item.url}>
                {item.name}
              </Hyperlink>
            </td>
          </tr>
        ))}
      </table>
    </InfoBox>
  );
};

const Projection = () => {
  const { t } = useTranslation();
  const [countries, setCountries] = React.useState<Country[]>([]);
  const [country, setCountry] = React.useState<Country>();

  const [allCoordinateSystems, setAllCoordinateSystems] = React.useState<CoordinateSystemItem[]>(
    [],
  );
  const [coordinateSystems, setCoordinateSystems] = React.useState<CoordinateSystemItem[]>([]);
  const [coordinateSystem, setCoordinateSystem] = React.useState<CoordinateSystemItem>();

  const [allGeoids, setAllGeoids] = React.useState<CoordinateSystemItem[]>([]);
  const [geoids, setGeoids] = React.useState<CoordinateSystemItem[]>([]);
  const [geoid, setGeoid] = React.useState<CoordinateSystemItem>();

  const addGeoidDialog = useDialog();
  const noGeoid = useMemo(
    () => ({
      id: noGeoidId,
      name: t('notSelected', { ns: 'common' }),
      countryId: null,
    }),
    [t],
  );

  const onCountryChanged = useCallback(
    (id: string) => {
      if (country?.id === id) {
        return;
      }
      const newCountry = countries.find((x) => x.id === id);

      if (newCountry) {
        setCountry(newCountry);

        setCoordinateSystem((oldValue) => {
          setCoordinateSystems(filterByCountry(allCoordinateSystems, oldValue ?? placeholder, id));
          return oldValue ?? placeholder;
        });

        setGeoid((oldValue) => {
          setGeoids(filterByCountry(allGeoids, oldValue ?? noGeoid, id));
          return oldValue ?? noGeoid;
        });
      }
    },
    [allCoordinateSystems, allGeoids, countries, country, noGeoid],
  );

  const { onCoordinateSystemChanged, isPendingPutCoordinateSystem } =
    usePutCoordinateSystemMutation();
  const { onGeoidChanged, isPendingPutGeoid } = usePutGeoidMutation();

  const { data, isLoading, errorList } = useCoordinateSystemsAndGeoidsQuery();

  React.useEffect(() => {
    if (!isDefined(data)) {
      return;
    }

    const selectedCoordinateSystem = getSelectedCoordinateSystem(data.coordinateSystems);
    const selectedGeoid = isDefined(ProjectStore.instance.geoid)
      ? mapGeoid(ProjectStore.instance.geoid)
      : undefined;

    const selectedCountry = selectedCoordinateSystem
      ? data.countries.find((x) => x.id === selectedCoordinateSystem?.countryId)
      : data.countries.find((x) => x.name === 'Sweden');

    const csList = filterByCountry(
      data.coordinateSystems,
      selectedCoordinateSystem ?? placeholder,
      selectedCountry?.id,
    );
    const geoidList = filterByCountry(data.geoids, selectedGeoid ?? noGeoid, selectedCountry?.id);

    // Ensure noGeoid is always in the list at the top.
    if (isDefined(selectedGeoid) && selectedGeoid.id !== noGeoid.id) {
      geoidList.unshift(noGeoid);
    }

    setAllGeoids(data.geoids);
    setAllCoordinateSystems(data.coordinateSystems);
    setCountries(data.countries);

    setCountry(selectedCountry ?? countryAll);

    setCoordinateSystems(csList);
    setCoordinateSystem(selectedCoordinateSystem ?? placeholder);

    setGeoids(geoidList);
    setGeoid(selectedGeoid ?? noGeoid);
  }, [data, noGeoid]);

  return (
    <Component>
      <SectionTitle>
        {t('projectSettings.projection.title', { ns: 'components' }).toUpperCase()}
      </SectionTitle>

      <OverlayLoader
        visible={
          isDefined(errorList) || isLoading || isPendingPutCoordinateSystem || isPendingPutGeoid
        }
      >
        <Stack spacing={1}>
          <LabelledContainer text={t('projectSettings.projection.country', { ns: 'components' })}>
            {(formElementId) => (
              <Select
                id={formElementId()}
                options={countries}
                value={country?.id}
                onChange={(e) => onCountryChanged(e.target.value)}
              />
            )}
          </LabelledContainer>

          <LabelledContainer text={t('projectSettings.projection.title', { ns: 'components' })}>
            {(formElementId) => (
              <DropdownSuggestion
                id={formElementId()}
                placeholder={coordinateSystem?.name}
                suggestions={coordinateSystems}
                value={coordinateSystem?.id}
                width={'100%'}
                onSuggestionSelected={(suggestion) =>
                  onCoordinateSystemChanged(suggestion, setCoordinateSystem)
                }
              />
            )}
          </LabelledContainer>

          <LabelledContainer
            text={t('projectSettings.projection.geoidModel', { ns: 'components' })}
          >
            {(formElementId) => (
              <>
                <DropdownSuggestion
                  id={formElementId()}
                  placeholder={geoid?.name}
                  suggestions={geoids}
                  value={geoid?.id}
                  width={'100%'}
                  onSuggestionSelected={(suggestion) => onGeoidChanged(suggestion, setGeoid)}
                />

                <GeoidInfo geoid={geoid} />

                {UserStore.instance.isOrganizationAdmin() && (
                  <Button variant="contained" onClick={() => addGeoidDialog.show()}>
                    {t('projectSettings.projection.addGeoidModel', { ns: 'components' })}
                  </Button>
                )}
              </>
            )}
          </LabelledContainer>
          {errorList}
        </Stack>
      </OverlayLoader>

      {addGeoidDialog.render(
        <AddGeoidDialog
          onClose={(newGeoid) => {
            if (newGeoid) {
              setAllGeoids([mapGeoid(newGeoid), ...allGeoids]);
              setGeoids([mapGeoid(newGeoid), ...geoids]);
            }
            addGeoidDialog.hide();
          }}
        />,
      )}
    </Component>
  );
};

const Component = styled.div`
  table {
    border-collapse: collapse;
    border-collapse: separate;
    border-spacing: 0.2em;

    td:first-child {
      padding-right: 0.5em;
    }
  }

  ${ButtonStyled} {
    margin-top: 1em;
  }
`;

Projection.styled = Component;

export { Projection };
