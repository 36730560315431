import axios from 'axios';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { FileModel } from '../../../../../../../typings/api/skymap/rest/v1/.common';
import { CreatePoiParameters } from '../../../../../../../typings/api/skymap/rest/v1/asset';
import { SkyMapAxiosServiceFactory } from '../../../../../../js/services/axios/skymap-axios-service-factory';
import { UserStore } from '../../../../../../js/stores/user-store';
import { RequestParamsToApiPaths } from '../../../../../../js/utils/typescript-utils';
import { AssetManager } from '../../../../../../js/viewer/asset-manager';
import { Comment } from '../../../../../../js/viewer/elements/comment';
import { errorUtils, getResponseErrorParameterValue } from '../../../../../api/error-handling';
import { useDefaultAssetName } from '../../../../../hooks/use-default-asset-name';
import { useDialog } from '../../../../../hooks/use-dialog';
import { useErrorHandling } from '../../../../../hooks/use-error-handling';
import { getSelectedProjectId } from '../../../../../routing/utils';
import { Button } from '../../../../button/button';
import { Dialog } from '../../../../dialog/dialog';
import { FileManagerSelectionDialog } from '../../../../file-manager/dialogs/file-manager-selection-dialog/file-manager-selection-dialog';
import { InfoBox } from '../../../../info-box/info-box';
import { LabelledContainer } from '../../../../labelled-container/labelled-container';
import { Stack } from '../../../../stack/stack';
import { TextArea } from '../../../../text-area/text-area';
import { TextBox } from '../../../../text-box/text-box';
import { CommentFileListFileModel, CommentFilesList } from './comment-files-list';

interface Props {
  comment: Comment;
  /**
   * To be used when caller is a React component.
   */
  onClose?: (saved: boolean) => void;

  /**
   * To be used when caller is a AngularJS component.
   *
   * Could not get parameter to work. However, this parameter is not used within the AngularJS
   * component anyway (AngularJS SkyView component).
   *
   * TODO: Remove when AngularJS SkyView component is removed.
   */
  angularJsFuncOnClose?: () => void;
}

enum Status {
  Idle,
  Loading,
  LoadingError,
  Saving,
  SavingError,
}

type ApiRequestPath = RequestParamsToApiPaths<CreatePoiParameters>;

const CommentDialog = (props: Props) => {
  const { t } = useTranslation();
  const saveButtonRef = React.useRef<HTMLButtonElement>(null);
  const closeButtonRef = React.useRef<HTMLButtonElement>(null);

  const [files, setFiles] = React.useState<CommentFileListFileModel[]>([]);
  const { handleError, getErrorForPath, clearErrorForPath } = useErrorHandling<ApiRequestPath>();
  const [status, setStatus] = React.useState(Status.Idle);
  const [name, setName] = React.useState('');
  const [text, setText] = React.useState('');

  const defaultAssetName = useDefaultAssetName('comment');

  const fileManagerDialog = useDialog();

  const load = React.useCallback(async () => {
    if (!props.comment.assetId) {
      return;
    }

    setStatus(Status.Loading);

    try {
      const { data: v1Comment } = await SkyMapAxiosServiceFactory.instance
        .createAssetServiceV1()
        .getComment({
          path: {
            commentId: props.comment.assetId,
          },
          query: {
            include: ['comment.files'],
            orderBy: ['comment.files.name:asc'],
          },
        });
      setName(props.comment.name);
      setText(props.comment.comment ?? '');
      setFiles(v1Comment.files ?? []);
      setStatus(Status.Idle);
    } catch (err) {
      if (!axios.isCancel(err)) {
        handleError(err);
        setStatus(Status.LoadingError);
      }
    }
  }, [props.comment, handleError]);

  React.useEffect(() => {
    void load();
  }, [load, props.comment]);

  const close = (saved: boolean) => {
    props.onClose?.(saved);
    props.angularJsFuncOnClose?.();
  };

  const save = async () => {
    try {
      setStatus(Status.Saving);
      if (props.comment.assetId) {
        await update();
      } else {
        await create();
      }

      AssetManager.instance.publishAssetListChanged();
      close(true);
    } catch (err) {
      setStatus(Status.SavingError);

      if (!axios.isCancel(err)) {
        handleError(err);

        // Handle error when files are not found.
        errorUtils.isResponseError(
          err,
          'resource_not_found',
          'file:not_found_multiple',
          (errorItem) => {
            const notFoundFileIds = getResponseErrorParameterValue<string[]>(errorItem, 'fileIds');

            // Mark all files not found as deleted.
            setFiles(
              files.map((x) =>
                notFoundFileIds?.includes(x.id)
                  ? {
                      ...x,
                      markAsDeleted: true,
                    }
                  : x,
              ),
            );
          },
        );
      }
    }
  };

  const update = async () => {
    const newName = name.trim() || defaultAssetName;
    const newText = text.trim();

    await SkyMapAxiosServiceFactory.instance.createAssetServiceV1().patchComment({
      body: {
        name: newName,
        comment: newText || undefined,
        fileIds: files.map((x) => x.id),
      },
      path: {
        commentId: props.comment.assetId!,
      },
    });

    props.comment.onSaved({
      author: UserStore.instance.getName(),
      edited: new Date(),
      timelineContainerId: props.comment.timelineContainerId,
      files,
      properties: {
        name,
        comment: text || undefined,
      },
    });
  };

  const create = async () => {
    // Create a POI along with a comment (both represented by dynamic asset for now).
    const { data: createResp } = await SkyMapAxiosServiceFactory.instance
      .createAssetServiceV1()
      .createPoi({
        body: {
          lat: props.comment.wgs84!.lat,
          lng: props.comment.wgs84!.lng,
          mas: props.comment.MAS,
          name: name,
          comment: text || undefined,
          timelineContainerId: props.comment.timelineContainerId ?? undefined,
          fileIds: files.map((x) => x.id),
        },
        query: {
          projectId: getSelectedProjectId()!,
        },
      });

    // Set asset id in order to make asset expandable in the asset menu.
    // todo: Data set should be returned from API. Refactor when asset component structure is
    // todo: decided and endpoints moved from API v0 to v1.
    props.comment.onSaved({
      author: UserStore.instance.getName(),
      edited: new Date(),
      id: createResp.id,
      timelineContainerId: props.comment.timelineContainerId,
      properties: {
        name: name,
        comment: text || undefined,
      },
    });

    AssetManager.instance.addAsset(props.comment);
    AssetManager.instance.select(props.comment);
  };

  const addFiles = (newFiles: FileModel[]) => {
    const newState = files;
    for (const file of newFiles) {
      if (!files.some((x) => x.id === file.id)) {
        newState.push(file);
      }
    }

    newState.sort((a, b) => a.name.localeCompare(b.name));
    setFiles(newState);
  };

  const renderContent = () => {
    switch (status) {
      case Status.Loading:
        return renderLoading();
      case Status.LoadingError:
        return renderLoadingError();
      case Status.Idle:
      case Status.Saving:
      case Status.SavingError:
        return renderIdle();
    }
  };

  const renderLoading = () => {
    return <InfoBox color="yellow">{t('oneMoment', { ns: 'common' })}</InfoBox>;
  };

  const renderLoadingError = () => {
    return (
      <InfoBox color="red">{t('commentDialog.couldNotFetchComment', { ns: 'components' })}</InfoBox>
    );
  };

  const renderIdle = () => {
    return (
      <Stack spacing={1}>
        <LabelledContainer text={t('commentDialog.name', { ns: 'components' })}>
          {(formElementId) => (
            <TextBox
              autoFocus={true}
              disabled={status === Status.Saving}
              errorMessage={getErrorForPath('body.name')}
              id={formElementId()}
              placeholder={defaultAssetName}
              value={name}
              onChange={(e) => {
                setName(e.target.value);
                clearErrorForPath('body.name');
              }}
            />
          )}
        </LabelledContainer>
        <LabelledContainer text={t('commentDialog.text', { ns: 'components' })}>
          {(formElementId) => (
            <TextArea
              disabled={status === Status.Saving}
              errorMessage={getErrorForPath('body.comment')}
              id={formElementId()}
              rows={5}
              value={text}
              onChange={(e) => {
                setText(e.target.value);
                clearErrorForPath('body.comment');
              }}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  e.stopPropagation();
                }
              }}
            />
          )}
        </LabelledContainer>
        <LabelledContainer text={t('commentDialog.files', { ns: 'components' })}>
          {files.some((x) => x.markAsDeleted) && (
            <InfoBox bottomMargin={true} color="red" leftIcon={{ icon: ['fad', 'exclamation'] }}>
              {t('commentDialog.filesNoLongerExist', { ns: 'components' })}
            </InfoBox>
          )}

          <TableContainer>
            {files.length === 0 ? (
              <InfoBox color="yellow">
                {t('commentDialog.noFilesChoosen', { ns: 'components' })}
              </InfoBox>
            ) : (
              <CommentFilesList
                files={files}
                showRemoveIcon={true}
                onFileRemoved={(file) => {
                  setFiles(files.filter((x) => x.id !== file.id));
                }}
              />
            )}
          </TableContainer>
        </LabelledContainer>
      </Stack>
    );
  };

  const disableButtons = [Status.Loading, Status.Saving].includes(status);

  return (
    <>
      <Dialog
        closeButtonRef={closeButtonRef}
        closeIcon={false}
        closeOnDimmerClick={false}
        confirmButtonRef={saveButtonRef}
        maxHeight={false}
        width={400}
        onClose={() => close(false)}
      >
        {{
          header: props.comment.assetId
            ? t('commentDialog.titleEdit', { ns: 'components' })
            : t('commentDialog.titleNew', { ns: 'components' }),
          content: renderContent(),
          footer: {
            left: (
              <>
                <Button
                  color="secondary"
                  disabled={disableButtons}
                  variant="contained"
                  onClick={() => {
                    fileManagerDialog.show();
                  }}
                >
                  {t('commentDialog.chooseFiles', { ns: 'components' })}
                </Button>
              </>
            ),
            right: (
              <>
                <Button
                  color="primary"
                  disabled={disableButtons}
                  loading={status === Status.Saving}
                  ref={saveButtonRef}
                  variant="contained"
                  onClick={save}
                >
                  {t('save', { ns: 'common' })}
                </Button>
                <Button
                  disabled={status === Status.Saving}
                  ref={closeButtonRef}
                  variant="text"
                  onClick={() => close(false)}
                >
                  {t('cancel', { ns: 'common' })}
                </Button>
              </>
            ),
          },
        }}
      </Dialog>

      {fileManagerDialog.render(
        <FileManagerSelectionDialog
          selectionMode="CheckFiles"
          onCancel={() => {
            fileManagerDialog.hide();
          }}
          onSelect={(folders, files) => {
            addFiles(files);
            fileManagerDialog.hide();
          }}
        />,
      )}
    </>
  );
};
const TableContainer = styled.div`
  max-height: 200px;
  overflow-y: auto;
  overscroll-behavior: contain;
`;

CommentDialog.propTypes = {
  wrapWithLanguageProvider: PropTypes.any,
  comment: PropTypes.any,
  angularJsFuncOnClose: PropTypes.func,
};

export { CommentDialog };
