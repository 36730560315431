import React, { useState } from 'react';
import styled, { useTheme } from 'styled-components';

import { Button } from '../button/button';
import { LabelledContainer } from '../labelled-container/labelled-container';
import { Select } from '../select/select';
import { Stack } from '../stack/stack';

/**
 * Converts an interface to a type that is compatible with the JsonTemplate typings.
 */
export type ConvertInterfaceToJsonTemplateCompatibleType<T> = {
  // Extract primitive values (string, number, boolean)
  [K in keyof T as T[K] extends string | number | boolean | undefined
    ? K
    : T[K] extends object | undefined
      ? never
      : never]: T[K];

  // Recursively process objects (including optional objects)
} & {
  [K in keyof T as T[K] extends object | undefined
    ? K
    : never]: ConvertInterfaceToJsonTemplateCompatibleType<NonNullable<T[K]>>;
};

type JsonValueType = Record<string, any> | string | number | boolean;

type JsonEntry<T extends JsonValueType> = {
  title: string;
  currentValue: T extends object ? JsonTemplate<T> : T;
  optional: boolean;
  excluded?: boolean;
  values?: T[];
};

export type JsonTemplate<T extends Record<string, JsonValueType>> = {
  [K in Extract<keyof T, string>]: JsonEntry<T[K]>;
};

type EntryProps<T extends JsonValueType> = {
  key: keyof T;
  entry: JsonEntry<T>;
  parentDisabled?: boolean;
};

const yesNoOptions = [
  { id: '0', name: 'No' },
  { id: '1', name: 'Yes' },
];

function Entry<T extends JsonValueType>({ key, entry, parentDisabled = false }: EntryProps<T>) {
  const [isEnabled, setIsEnabled] = useState(!entry.excluded);
  const [currentValue, setCurrentValue] = useState(entry.currentValue);
  const isDisabled = parentDisabled || (entry.optional && !isEnabled);
  const theme = useTheme();

  const renderValue = () => {
    if (typeof entry.currentValue === 'boolean') {
      return (
        <Select
          disabled={isDisabled}
          options={yesNoOptions}
          value={currentValue === true ? yesNoOptions[1].id : yesNoOptions[0].id}
          onChange={(e) => {
            entry.currentValue = (e.target.value ===
              yesNoOptions[1].id) as typeof entry.currentValue;
            setCurrentValue(entry.currentValue);
          }}
        />
      );
    } else if (typeof entry.currentValue === 'string' && entry.values) {
      return (
        <Select
          disabled={isDisabled}
          options={(entry.values as string[]).map((x) => ({ id: x, name: x }))}
          value={currentValue as string}
          onChange={(e) => {
            entry.currentValue = e.target.value as typeof entry.currentValue;
            setCurrentValue(entry.currentValue);
          }}
        />
      );
    } else if (typeof entry.currentValue === 'string' || typeof entry.currentValue === 'number') {
      return (
        <input
          disabled={isDisabled}
          type={typeof entry.currentValue === 'string' ? 'text' : 'number'}
          value={currentValue as string}
          onChange={(e) => {
            entry.currentValue =
              typeof entry.currentValue === 'number'
                ? (Number(e.target.value) as typeof entry.currentValue)
                : (e.target.value as typeof entry.currentValue);

            setCurrentValue(entry.currentValue);
          }}
        />
      );
    } else if (typeof entry.currentValue === 'object') {
      return (
        <Stack direction="column" spacing={0.5}>
          {Object.entries(entry.currentValue).map(([nestedKey, nestedEntry]) => (
            <div key={nestedKey}>
              <Entry entry={nestedEntry} key={nestedKey} parentDisabled={isDisabled} />
            </div>
          ))}
        </Stack>
      );
    }
    return null;
  };

  return (
    <Stack
      alignItems="flex-start"
      backgroundColor={theme.color.gray.lightest}
      direction="row"
      padding={'0.5em'}
      spacing={0.2}
    >
      {entry.optional && (
        <input
          checked={isEnabled}
          type="checkbox"
          onChange={() =>
            setIsEnabled((oldValue) => {
              entry.excluded = oldValue;
              return !oldValue;
            })
          }
        />
      )}
      <LabelledContainer key={key as string} text={entry.title}>
        {isEnabled && renderValue()}
      </LabelledContainer>
    </Stack>
  );
}

type Props<T extends Record<string, JsonValueType>> = {
  template: JsonTemplate<T>;
  onConfirm: (payload: T) => void;
};

type JsonRecord = Record<string, any>;

export function JsonEditor<T extends JsonRecord>({ template, onConfirm }: Props<T>) {
  const [jsonString, setJsonString] = useState<string>('');

  const extractJson = () => {
    const iterateTemplate = (template: JsonTemplate<T>): T => {
      const result: JsonRecord = {};
      Object.entries(template).forEach(([key, entry]) => {
        if (!entry.excluded) {
          if (typeof entry.currentValue === 'object' && !entry.excluded) {
            result[key] = iterateTemplate(entry.currentValue);
          } else {
            result[key] = entry.currentValue;
          }
        }
      });
      return result as T;
    };

    const extractedJson = iterateTemplate(template);
    setJsonString(JSON.stringify(extractedJson, null, 2));
    onConfirm(extractedJson);
  };

  return (
    <Component direction="column" spacing={1}>
      {Object.entries(template).map(([key, entry]) => (
        <Entry entry={entry} key={key} />
      ))}
      <Button color="primary" variant="contained" onClick={extractJson}>
        Run custom pipeline
      </Button>
      {jsonString && <JsonOutput>{jsonString}</JsonOutput>}
    </Component>
  );
}

const Component = styled(Stack)`
  padding: 1em;
  border: 1px solid ${({ theme }) => theme.color.gray.light};
`;

const JsonOutput = styled.pre`
  margin-top: 1em;
  padding: 1em;
  background-color: ${({ theme }) => theme.color.gray.light};
  border: 1px solid ${({ theme }) => theme.color.gray.medium};
  font-family: monospace;
  white-space: pre-wrap;
`;
