import React from 'react';
import { useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { emailRegex } from '../../../js/utils/text/validation';
import { useRouter } from '../../hooks/use-router';
import { reset } from '../../utils/styled-reset';
import { Button } from '../button/button';
import { InfoBox } from '../info-box/info-box';
import { LabelledContainer } from '../labelled-container/labelled-container';
import { ButtonContainer } from '../password-reset-form/styled/button-container';
import { Stack } from '../stack/stack';
import { TextBox } from '../text-box/text-box';
import { useRequestPasswordQuery } from './use-request-password-query';

export type Form = {
  email: string;
};

const RequestPasswordResetLinkForm = () => {
  const { t } = useTranslation();
  const { redirectToAngularState } = useRouter();

  const {
    formState: { errors },
    handleSubmit,
    register,
    setError,
  } = useForm<Form>({
    defaultValues: {
      email: '',
    },
  });

  const {
    buildErrorList,
    excludeErrorPaths,
    getErrorForPath,
    hasError,
    mutation: signInMutation,
  } = useRequestPasswordQuery();

  React.useEffect(() => {
    setError('email', {
      type: 'custom',
      message: getErrorForPath('body.email'),
    });
  }, [getErrorForPath, setError]);

  const excludeDefaultErrors = excludeErrorPaths(['body.email', 'body.password']);

  return (
    <Component>
      <Stack spacing={1.5}>
        <h1>{t('requestPasswordResetForm.title', { ns: 'components' })}</h1>

        {signInMutation.isSuccess ? (
          <InfoBox color="green" leftIcon={{ icon: ['fad', 'check'] }}>
            <Trans
              components={{ br: <br /> }}
              i18nKey="requestPasswordResetForm.success"
              ns="components"
            />
          </InfoBox>
        ) : (
          <InfoBox color="yellow" leftIcon={{ icon: ['fad', 'info'] }}>
            {t('requestPasswordResetForm.info', { ns: 'components' })}
          </InfoBox>
        )}

        <form onSubmit={handleSubmit((form) => signInMutation.mutate(form))}>
          <Stack spacing={1}>
            <LabelledContainer
              required={true}
              text={t('requestPasswordResetForm.form.email', { ns: 'components' })}
            >
              {(formElementId) => {
                const registration = register('email', {
                  required: true,
                  pattern: {
                    value: emailRegex,
                    message: t('requestPasswordResetForm.emailInvalidMessage', {
                      ns: 'components',
                    }),
                  },
                });
                return (
                  <TextBox
                    {...registration}
                    autoFocus={true}
                    disabled={signInMutation.isPending || signInMutation.isSuccess}
                    errorMessage={errors.email?.message}
                    id={formElementId()}
                    onChange={async (e) => {
                      // Trim the input value on each change
                      e.target.value = e.target.value.trim();
                      await registration.onChange(e);
                    }}
                  />
                );
              }}
            </LabelledContainer>

            {hasError(excludeDefaultErrors) &&
              buildErrorList({
                filter: excludeDefaultErrors,
              })}

            {signInMutation.isSuccess ? (
              <ButtonContainer direction="row" justifyContent="flex-end">
                <Button
                  color="primary"
                  disabled={signInMutation.isPending}
                  type="button"
                  variant="contained"
                  onClick={() => redirectToAngularState('signin')}
                >
                  {t('goToLoginPage', { ns: 'common' })}
                </Button>
              </ButtonContainer>
            ) : (
              <ButtonContainer direction="row" justifyContent="space-between">
                <Button
                  color="secondary"
                  disabled={signInMutation.isPending}
                  type="button"
                  variant="text"
                  onClick={() => redirectToAngularState('signin')}
                >
                  {t('goToLoginPage', { ns: 'common' })}
                </Button>
                <Button
                  color="primary"
                  loading={signInMutation.isPending}
                  type="submit"
                  variant="contained"
                >
                  {t('requestPasswordResetForm.sendButton', { ns: 'components' })}
                </Button>
              </ButtonContainer>
            )}
          </Stack>
        </form>
      </Stack>
    </Component>
  );
};

const Component = styled.div`
  ${reset}
  font-size: 14px;

  h1 {
    font-size: 1.2em;
    font-weight: 500;
    margin: 0;
  }
`;

export { RequestPasswordResetLinkForm };
