import { FontAwesomeIconProps } from '@fortawesome/react-fontawesome';
import { format } from 'date-fns';
import { t } from 'i18next';
import { extname } from 'path';
import PubSub from 'pubsub-js';
import React, { useMemo } from 'react';
import styled from 'styled-components';

import { GeodataModel } from '../../../../../typings/api/skymap/rest/v1/.common';
import { GcpXmlParser } from '../../../../js/components/gcp/gcp-lib';
import { publish, SubscriptionTopic } from '../../../../js/messaging/pubsub';
import { SkyMapAxiosServiceFactory } from '../../../../js/services/axios/skymap-axios-service-factory';
import { CompanyStore } from '../../../../js/stores/company.store';
import { ProjectStore } from '../../../../js/stores/project-store';
import { UserStore } from '../../../../js/stores/user-store';
import { removeSymbolsAndWhiteSpace } from '../../../../js/utils/text/text';
import { isDefined } from '../../../../js/utils/variables';
import { WebApi } from '../../../../js/webAPI/web-api';
import { ArtifactFolder, ArtifactItem } from './artifact-folder';

interface Props {
  resourceUrls: GeodataModel['resourceUrls'];
  selectedItem: Omit<GeodataModel, 'resourceUrls'>;
}

const artifactKeys = [
  // MetaShape artifacts.
  'report',
  'log',
  'project',
  'cameras',
  'boundary',
  'zip',
  'las',
  'laz',
  'tiled-model',
  'filtered_point_cloud',
  'filtered_point_cloud.las',
  'obj',
  'terrain_model',
  'tif',
  'tfw',
  'tif_01',
  'tfw_01',

  // ODM artifacts.
  'odm_proj.txt',
  'odm_log.json',
  'odm_report.pdf',
  'odm_orthophoto.tif',
  'odm_sampled_pointcloud.las',
  'odm_original_pointcloud.laz',
  'odm_filtered_point_cloud.las',
] as const;

type ArtifactKey = (typeof artifactKeys)[number];

interface Resource {
  title: string | ((geodata: GeodataModel) => string);
  icon: FontAwesomeIconProps['icon'];
}

const resources: Record<ArtifactKey, Resource> = {
  report: { icon: 'file-pdf', title: 'Rapport (.PDF)' },
  project: { icon: 'file-archive', title: 'Projekt (.ZIP)' },
  log: {
    icon: 'file-lines',
    title: (geodata) => (geodata.isLegacyProcess ? '' : 'Log (.txt)'),
  },
  cameras: { icon: 'file-csv', title: 'GCP / Markstöd (.CSV)' },
  boundary: {
    icon: 'file-code',
    title: (geodata: GeodataModel) =>
      `Begränsningspolygon (${extname(geodata.boundaryFile ?? '').toUpperCase()})`,
  },
  zip: { icon: 'file-archive', title: 'Bilder (.ZIP)' },
  las: { icon: 'file-export', title: 'Punktmoln (.LAS)' },
  laz: { icon: 'file-export', title: 'Punktmoln (.LAZ)' },
  'tiled-model': { icon: 'file-archive', title: 'Cesium 3D-tiles (.ZIP)' },
  filtered_point_cloud: {
    icon: 'file-export',
    title: (geodata: GeodataModel) =>
      `Utglesat punktmoln (.${geodata.filteredPointCloud?.format.toLowerCase() ?? ''})`,
  },
  'filtered_point_cloud.las': {
    icon: 'file-export',
    title: 'Utglesat punktmoln (.LAS)',
  },
  obj: { icon: 'file-code', title: '3D-modell (.OBJ)' },
  terrain_model: { icon: 'file-code', title: 'Markmodell (.DXF)' },
  tif: { icon: 'file-image', title: 'Ortofoto (.TIF)' },
  tfw: { icon: 'file-pen', title: 'Ortofoto WORLD-fil (.TFW)' },
  tif_01: { icon: 'file-image', title: 'GSD 0.1 Ortofoto (.TIF)' },
  tfw_01: { icon: 'file-pen', title: 'GSD 0.1 Ortofoto WORLD-fil (.TFW)' },
  'odm_proj.txt': { icon: 'file-lines', title: 'Proj (.txt)' },
  'odm_log.json': { icon: 'file-lines', title: 'Log (.JSON)' },
  'odm_report.pdf': { icon: 'file-pdf', title: 'Rapport (.PDF)' },
  'odm_orthophoto.tif': { icon: 'file-image', title: 'Ortofoto (.TIF)' },
  'odm_sampled_pointcloud.las': { icon: 'file-export', title: 'Punktmoln (sampled) (.LAS)' },
  'odm_original_pointcloud.laz': { icon: 'file-export', title: 'Punktmoln (dense) (.LAZ)' },
  'odm_filtered_point_cloud.las': { icon: 'file-export', title: 'Utglesat punktmoln (.LAS)' },
};

const resourceGroups: Record<string, { title: string; values: ArtifactKey[] }> = {
  project: {
    title: 'Projektdata',
    values: [
      'report',
      'log',
      'project',
      'cameras',
      'zip',
      'boundary',
      'odm_proj.txt',
      'odm_log.json',
      'odm_report.pdf',
    ],
  },
  pointClouds: {
    title: 'Punktmoln',
    values: [
      'las',
      'laz',
      'filtered_point_cloud',
      'odm_sampled_pointcloud.las',
      'odm_filtered_point_cloud.las',
      'odm_original_pointcloud.laz',
    ],
  },
  models3d: { title: '3D-modeller', values: ['tiled-model', 'obj', 'terrain_model'] },
  models2d: {
    title: 'Ortofoton',
    values: ['tif', 'tfw', 'tif_01', 'tfw_01', 'odm_orthophoto.tif'],
  },
};

const GeodataArtifacts = (props: Props) => {
  const filteredUrls = useMemo(() => {
    const companyId = ProjectStore.instance.project?.companyId;
    const alpha2Code = isDefined(companyId)
      ? CompanyStore.instance.getCompanyCountry(companyId)?.alpha2Code
      : undefined;

    if (!UserStore.instance.isOrganizationAdmin() && isDefined(alpha2Code) && alpha2Code === 'DE') {
      return props.resourceUrls?.filter((x) => x.name !== 'report');
    }

    return props.resourceUrls;
  }, [props.resourceUrls]);

  if (!filteredUrls) {
    return null;
  }

  const doesGeodataHaveGroupResources = (values: string[]) => {
    return filteredUrls?.some((resourceUrl) => values.includes(resourceUrl.name));
  };

  const createDownloadImageRequest = async () => {
    const result = await SkyMapAxiosServiceFactory.instance
      .createGeodataServiceV0()
      .createImageDownloadRequest({
        path: {
          geodataId: props.selectedItem.id,
        },
      });
    publish(SubscriptionTopic.WebsocketMessageRecieved, {
      message: 'BatchDownloadUpdated',
      status: 'RUNNING',
      batchDownloadId: result.batchDownloadId,
      fileName: `${ProjectStore.instance.project!.name}_${format(Date.now(), 'yyyyMMdd_HHmm')}.zip`,
      progress: 0,
      projectName: ProjectStore.instance.project!.name,
    });
    publish(SubscriptionTopic.ToastrMessage, {
      type: 'info',
      message: t('batchDownload.processTriggered', { ns: 'components' }),
    });
  };

  const getGcpFileUrl = async (url: string): Promise<void> => {
    const xml = (await WebApi.fetchS3Data(url)) as string;
    const gcpXmlParser = new GcpXmlParser(xml);

    if (gcpXmlParser.gcpPoints.length === 0) {
      PubSub.publish('notify', { code: 'err0511' });
      return;
    }

    const gcpFileContent = gcpXmlParser.gcpPoints
      .map((point) => `${point.name};${point.northing};${point.easting};${point.height}`)
      .join('\n');

    const a = document.createElement('a');
    const fileType = 'csv';
    const cooordinateSystemName = removeSymbolsAndWhiteSpace(
      ProjectStore.instance.coordinateSystem!.name,
    );
    a.download = `${ProjectStore.instance.project!.name}_${props.selectedItem.name}_${
      cooordinateSystemName ?? 'points'
    }.csv`;
    const blob = new Blob([gcpFileContent], { type: fileType });
    a.href = URL.createObjectURL(blob);
    a.dataset.downloadurl = [fileType, a.download, a.href].join(':');
    a.click();
  };

  const generateArtifactItems = (values: ArtifactKey[]) => {
    return values.reduce((prev: ArtifactItem[], cur: ArtifactKey): ArtifactItem[] => {
      const resource = filteredUrls?.find((resourceUrl) => resourceUrl.name === cur);

      if (isDefined(resource)) {
        const artifactItem = resources[cur];
        const title =
          typeof artifactItem.title === 'function'
            ? artifactItem.title(props.selectedItem)
            : artifactItem.title;

        if (title !== '') {
          prev.push({
            icon: artifactItem.icon,
            title:
              typeof artifactItem.title === 'function'
                ? artifactItem.title(props.selectedItem)
                : artifactItem.title,
            url: resource.url,
            downloadProcess:
              cur === 'cameras'
                ? getGcpFileUrl
                : cur === 'zip'
                  ? createDownloadImageRequest
                  : undefined,
          });
        }
      }
      return prev;
    }, []);
  };

  if ((filteredUrls?.length ?? 0) === 0) {
    return null;
  }

  return (
    <Component>
      <h5>Nedladdningsbar data</h5>

      <Artifacts>
        {Object.entries(resourceGroups).map(
          ([key, group]) =>
            doesGeodataHaveGroupResources(group.values) && (
              <ArtifactFolder
                items={generateArtifactItems(group.values)}
                key={key}
                title={group.title}
              />
            ),
        )}
      </Artifacts>
    </Component>
  );
};

const Component = styled.div`
  display: flex;
  flex-direction: column;
  background-color: white;
  height: 100%;

  button {
    margin: 0.5em 0 0.5em 0;
    width: 10em;
  }
`;

const Artifacts = styled.div`
  border: 1px solid ${(props) => props.theme.color.gray.light};
  margin-right: 1em;
  margin-top: 0.5em;
`;

export { GeodataArtifacts };
