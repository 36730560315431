import { Document, Image, Page, pdf, StyleSheet, Text, View } from '@react-pdf/renderer';
import { t } from 'i18next';
import React from 'react';
import styled from 'styled-components';

import { ProjectStore } from '../../../../../../js/stores/project-store';
import { formattedDate } from '../../../../../../js/utils/dateUtils';
import {
  extractPlainTextFromHtmlString,
  normalizeString,
} from '../../../../../../js/utils/text/text';
import { reset } from '../../../../../utils/styled-reset';
import { useWmsAttributions } from '../../../../wms-map/wms-attributions';
import { pdfPageDimensions } from '../helpers/types';
import { downloadBlob } from '../helpers/utils';
import { CadDrawingReportImage, CadDrawingReportOptions } from './cad-drawing-pdf-report-menu';
import { CadDrawingScaleBar } from './cad-drawing-scale-bar';

type Props = {
  image: CadDrawingReportImage;
  preview: boolean;
  options: CadDrawingReportOptions;
};

export const ReactPdfWmsAttributions = () => {
  const attributions = useWmsAttributions();

  if (attributions.length === 0) {
    return null;
  }
  const clearHtmlAndNewLines = (htmlText: string) =>
    extractPlainTextFromHtmlString(htmlText).replaceAll(/\n/g, '').trim();
  return (
    <View style={styles.wmsAttributions}>
      <Text>{t('cadDrawingReport.backgroundMapProvidedBy', { ns: 'skyviewReport' })}</Text>
      {attributions.map((item, idx) => (
        <Text key={idx}>{clearHtmlAndNewLines(item)}</Text>
      ))}
      <Text>({formattedDate(Date.now())})</Text>
    </View>
  );
};

const CadDrawingPdfReport = (props: Props) => {
  return (
    <Document style={styles.document}>
      <Page dpi={72} orientation="landscape" size="A3" style={styles.page}>
        <View style={styles.content}>
          <ReactPdfWmsAttributions />
          <CadImage preview={props.preview} src={props.image.url} />
          <CadDrawingInfoPanel options={props.options} />
          <CadDrawingScaleBar image={props.image} />
        </View>
      </Page>
    </Document>
  );
};

/**
 * Since image tags works a little bit different in the exported
 * PDF and in the browser we return a different element for the Image
 * based on if it's a preview or not.
 */
const CadImage = (props: { src: string; preview: boolean }) => {
  return props.preview ? (
    <PreviewImage src={props.src} />
  ) : (
    <Image src={props.src} style={styles.image} />
  );
};

const CadDrawingInfoPanel = (props: { options: CadDrawingReportOptions }) => {
  if (!props.options.includeDrawingFrame) {
    return null;
  }

  return (
    <View style={styles.infoPanel}>
      <FreeTextField text={props.options.freeText} />

      <InfoPanelField
        label={t('cadDrawingReport.pdf.rightPanel.title', { ns: 'skyviewReport' })}
        text={props.options.title}
      />

      <InfoPanelField
        label={t('cadDrawingReport.pdf.rightPanel.projectName', { ns: 'skyviewReport' })}
        text={ProjectStore.instance.project!.name}
      />

      <InfoPanelField
        label={t('cadDrawingReport.pdf.rightPanel.description', { ns: 'skyviewReport' })}
        text={props.options.description}
      />

      <View style={styles.infoPanelField}>
        <View style={styles.dateField}>
          <Text style={styles.fieldLabel}>
            {t('cadDrawingReport.pdf.rightPanel.date', { ns: 'skyviewReport' })}
          </Text>
          <Text style={styles.textSmall}>{formattedDate(Date.now())}</Text>
        </View>
        <View style={styles.fieldItemContainer}>
          <Text style={styles.fieldLabel}>
            {t('cadDrawingReport.pdf.rightPanel.responsible', { ns: 'skyviewReport' })}
          </Text>
          <Text style={styles.textSmall}>{props.options.responsible}</Text>
        </View>
      </View>
    </View>
  );
};

export const InfoPanelField = (props: { label: string; text: string }) => {
  if (props.text.trim().length === 0) {
    return null;
  }

  return (
    <View style={styles.infoPanelField}>
      <View style={styles.fieldItemContainer}>
        <Text style={styles.fieldLabel}>{props.label}</Text>
        <Text style={styles.textMedium}>{props.text}</Text>
      </View>
    </View>
  );
};

export const FreeTextField = (props: { text: string }) => {
  if (props.text.trim().length === 0) {
    return null;
  }

  return <View style={styles.freeTextField}>{renderTextWithLinebreaks(props.text)}</View>;
};

function renderTextWithLinebreaks(text: string) {
  return text.split('\n').map((x, idx) => {
    return (
      <Text key={idx} style={styles.freeText}>
        {x}
      </Text>
    );
  });
}

export const CadDrawingPdfReportPreview = (props: Omit<Props, 'preview'>) => {
  return (
    <PreviewContainer>
      <CadDrawingPdfReport image={props.image} options={props.options} preview={true} />
    </PreviewContainer>
  );
};

/**
 * Styling is adjusted so that the in browser preview
 * will resemble the PDF-report as closely as possible.
 */
const PreviewContainer = styled.div`
  ${reset}
  box-sizing: reset;
  font-family: helvetica;
  line-height: normal;
  overflow: auto;
`;

const PreviewImage = styled.img`
  width: 100%;
  display: block;
`;

/**
 * Width in pixels for the info panel in the CAD drawing report.
 * This is the section where project name, author, description etc is written.
 */
const infoPanelWidth = Math.round(pdfPageDimensions.a3.landscape72Dpi.width * 0.2);
const pagePadding = pdfPageDimensions.a3.landscape72Dpi.defaultPadding;
/**
 * Styling is adjusted for 72 dpi resolution in the PDF.
 *
 * Note: react-pdf limits what stylings can be used for the PDF-export so
 * we can only use compatible stylings.
 */
const styles = StyleSheet.create({
  document: {
    display: 'flex',
    border: '1px solid #a5acaf',
  },
  page: {
    position: 'relative',
    padding: `${pagePadding}px ${pagePadding}px 0 ${pagePadding}px`,
    width: `${pdfPageDimensions.a3.landscape72Dpi.width}px`,
    height: `${pdfPageDimensions.a3.landscape72Dpi.height}px`,
  },
  content: {
    display: 'flex',
    position: 'relative',
    flexDirection: 'row',
    border: '1px solid #a5acaf',
  },
  wmsAttributions: {
    // Aligns the attribution text just outside of the frame.
    position: 'absolute',
    width: '100%',
    left: '0',
    top: '-12px',
    fontSize: '8px',
    display: 'flex',
    gap: '2px',
    flexDirection: 'row',
  },
  image: {
    width: '100%',
  },
  infoPanel: {
    display: 'flex',
    backgroundColor: 'white',
    position: 'absolute',
    right: 0,
    bottom: 0,
    flexDirection: 'column',
    width: `${infoPanelWidth}px`,
    maxHeight: '100%',
    borderLeft: '1px solid #a5acaf',
    boxSizing: 'border-box',
    overflow: 'hidden',
  },
  /**
   * This is the small label in the top left corner of a field in the cad drawing info panel.
   */
  fieldLabel: {
    width: '100%',
    position: 'absolute',
    top: 0,
    left: 0,
    fontSize: '6px',
    textAlign: 'left',
  },
  freeTextField: {
    display: 'flex',
    position: 'relative',
    flexDirection: 'column',
    padding: '2px',
    width: '100%',
    flex: 1,
    borderTop: '1px solid #a5acaf',
    boxSizing: 'border-box',
  },
  freeText: {
    fontSize: '12px',
    textAlign: 'left',
    minHeight: '14px', // This forces an empty row when a row is just white space.
  },
  infoPanelField: {
    display: 'flex',
    flexDirection: 'row',
    position: 'relative',
    gap: '2px',
    width: '100%',
    padding: '2px',
    borderTop: '1px solid #a5acaf',
    boxSizing: 'border-box',
  },
  dateField: {
    position: 'relative',
    width: '30%',
    display: 'flex',
    flexDirection: 'column',
  },
  fieldItemContainer: {
    position: 'relative',
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
  },
  textSmall: {
    marginTop: '8px',
    fontSize: '10px',
    textAlign: 'left',
  },
  textMedium: {
    fontSize: '12px',
    marginTop: '6px',
    textAlign: 'left',
  },
});

export async function exportCadDrawingPdfReport(
  image: CadDrawingReportImage,
  options: CadDrawingReportOptions,
) {
  const component = <CadDrawingPdfReport image={image} options={options} preview={false} />;

  const result = pdf(component);
  const blob = await result.toBlob();

  downloadBlob(
    blob,
    `${normalizeString(`${ProjectStore.instance.project!.name}_${formattedDate(Date.now())}`)}`,
    'pdf',
  );
}
