import React, { useCallback, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { clamp } from 'three/src/math/MathUtils';
import { throttle } from 'underscore';

import { useDialog } from '../../../hooks/use-dialog';
import { Button } from '../../button/button';
import { CollapsableSection } from '../../skyview/right-menu/components/collapsable-section';
import { RightPanelFooter } from '../../skyview/right-menu/components/styles';
import { GeodataImageList } from '../geodata-image-list/geodata-image-list';
import { GeodataContext } from '../geodata-state';
import { GcpList } from './gcp-list';
import { MarkerList } from './markers-list';
import { StartProcessDialog } from './start-process-dialog';

const minWidth = 300;
const defaultWidth = 500;

export const SfmViewerRightMenu = ({ parentWidth }: { parentWidth: number }) => {
  const startProcessDialog = useDialog();
  const [imageListExpanded, setImageListExpanded] = useState(true);
  const [gcpListExpanded, setGcpListExpanded] = useState(true);
  const [markerListExpanded, setMarkerListExpanded] = useState(true);
  const { t } = useTranslation();
  const { isGeodataProcessing } = useContext(GeodataContext);
  const [panelWidth, setPanelWidth] = useState(defaultWidth);

  const handleMouseDown = useCallback(
    (mouseDownEvent: React.MouseEvent, startWidth: number) => {
      mouseDownEvent.preventDefault();
      const maxWidth = Math.max(parentWidth / 2, defaultWidth);
      const onMouseMove = throttle((mouseMoveEvent: MouseEvent) => {
        const newWidth = startWidth + (mouseDownEvent.clientX - mouseMoveEvent.clientX);
        setPanelWidth(clamp(newWidth, minWidth, maxWidth));
        window.dispatchEvent(new Event('resize'));
      }, 50);

      const onMouseUp = () => {
        document.removeEventListener('mousemove', onMouseMove);
        document.removeEventListener('mouseup', onMouseUp);
      };

      document.addEventListener('mousemove', onMouseMove);
      document.addEventListener('mouseup', onMouseUp);
    },
    [setPanelWidth, parentWidth],
  );

  return (
    <Container panelWidth={panelWidth}>
      <SfmRightPanelContent>
        <CollapsableSection
          expanded={gcpListExpanded}
          title={t('gcpList.title', { ns: 'cloudProcessing' })}
          onSetExpanded={(state) => setGcpListExpanded(state)}
        >
          <GcpList />
        </CollapsableSection>

        <CollapsableSection
          expanded={markerListExpanded}
          title={t('markerList.title', { ns: 'cloudProcessing' })}
          onSetExpanded={(state) => setMarkerListExpanded(state)}
        >
          <MarkerList />
        </CollapsableSection>

        <CollapsableSection
          expanded={imageListExpanded}
          title={t('imageList.title', { ns: 'cloudProcessing' })}
          onSetExpanded={(state) => setImageListExpanded(state)}
        >
          <GeodataImageList />
        </CollapsableSection>
      </SfmRightPanelContent>

      <RightPanelFooter>
        <Button
          color="primary"
          loading={isGeodataProcessing}
          variant="contained"
          width="100%"
          onClick={() => startProcessDialog.show()}
        >
          {t('gcpPage.processDialog.title', { ns: 'cloudProcessing' })}
        </Button>
      </RightPanelFooter>

      <ResizeHandle onMouseDown={(e) => handleMouseDown(e, panelWidth)} />
      {startProcessDialog.render(<StartProcessDialog dialog={startProcessDialog} />)}
    </Container>
  );
};

const SfmRightPanelContent = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: auto;
  z-index: 0;
`;

const Container = styled.div<{ panelWidth: number }>`
  width: ${(props) => props.panelWidth}px;
  flex: none;

  display: flex;
  flex-direction: column;
  height: 100%;
  background-color: white;
  position: relative;
`;

const ResizeHandle = styled.div`
  width: 10px;
  cursor: ew-resize;
  position: absolute;
  top: 0;
  bottom: 0;
  left: -5px;
  background-color: rgba(0, 0, 0, 0.1);
  transition: background-color 0.2s;

  &:hover {
    background-color: rgba(0, 0, 0, 0.3);
  }
`;
