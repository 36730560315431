import React, { RefObject, useCallback, useContext, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { Color, Scene, Vector2, Vector3 } from 'three';

import { GcpModel } from '../../../../../typings/api/skymap/rest/v0/.common';
import { isDefined } from '../../../../js/utils/variables';
import { mouseMovementTreshold } from '../../../../js/viewer/input-handler';
import { PickingLine } from '../../../../js/viewer/tools/picking-line';
import { useObject3d } from '../../../hooks/three/use-object-3d';
import { usePointerEvent } from '../../../hooks/use-pointer-up-event';
import { Stack } from '../../stack/stack';
import { GeodataContext } from '../geodata-state';
import { sfmViewerLayering } from './layering';
import { GeodataImageItemWithMarkers, PlaceMarkersContext } from './place-markers-state';
import { CursorIntersectionResult } from './use-cursor';
import { getPositionFromGeodataImageTransform } from './use-geodata-cameras';

type Props = {
  intersection?: CursorIntersectionResult;
  camerasObjectUuid?: string;
  gcpObjectUuid?: string;
  webGlScene?: Scene;
  pointerRef?: RefObject<HTMLDivElement>;
};

export const SfmViewerTooltip = ({
  intersection,
  camerasObjectUuid,
  gcpObjectUuid,
  webGlScene,
  pointerRef,
}: Props) => {
  const measuringCursor = useRef(new PickingLine(new Color(0, 1, 0)));
  const [distance, setDistance] = useState<number>();
  const { signer, geodata } = useContext(GeodataContext);
  const {
    selectImage,
    selectGcp,
    setShowPlaceMarkersDialog,
    setSortedImageList,
    imagesWithMarkers,
  } = useContext(PlaceMarkersContext);
  const [hoveredImage, setHoveredImage] = useState<{
    image: GeodataImageItemWithMarkers;
    thumbnailUrl: string;
  }>();
  const [savedIntersection, setSavedIntersection] = useState<CursorIntersectionResult>();
  const [hoveredGcp, setHoveredGcp] = useState<GcpModel>();
  const [lastMousePosition, setLastMousePosition] = useState<Vector2>(new Vector2());

  useEffect(() => {
    if (
      !isDefined(intersection) ||
      !isDefined(signer) ||
      !isDefined(intersection.intersection.index)
    ) {
      setHoveredImage(undefined);
      setHoveredGcp(undefined);
      return;
    }

    if (intersection.intersection.object.uuid === camerasObjectUuid) {
      const image = imagesWithMarkers[intersection.intersection.index];
      setHoveredImage({
        image,
        thumbnailUrl: signer.getSignedRequest(`thumbnails/${image.imageIdentifier}`),
      });
    } else {
      setHoveredImage(undefined);
    }

    if (
      isDefined(geodata.gcpCollection) &&
      intersection.intersection.object.uuid === gcpObjectUuid
    ) {
      const gcp = geodata.gcpCollection.points[intersection.intersection.index];
      setHoveredGcp(gcp);
    } else {
      setHoveredGcp(undefined);
    }
  }, [intersection, camerasObjectUuid, gcpObjectUuid, imagesWithMarkers, signer, geodata]);

  useEffect(() => {
    if (!isDefined(savedIntersection) || !isDefined(intersection)) {
      setDistance(undefined);
      measuringCursor.current.visible = false;
      return;
    }

    measuringCursor.current.visible = true;
    measuringCursor.current.setNonIndexedVertices([
      intersection.localPosition,
      savedIntersection.localPosition,
    ]);

    setDistance(intersection.localPosition.distanceTo(savedIntersection.localPosition));
  }, [savedIntersection, intersection]);

  useObject3d(webGlScene, measuringCursor.current);

  const onLeftUp = useCallback(
    (mouseEvent: MouseEvent) => {
      if (
        new Vector2(mouseEvent.clientX, mouseEvent.clientY).distanceTo(lastMousePosition) >
        mouseMovementTreshold
      ) {
        return;
      }

      if (isDefined(hoveredImage) && isDefined(hoveredImage.image.transform)) {
        const coordinates = getPositionFromGeodataImageTransform(hoveredImage.image.transform);
        const position = new Vector3(coordinates.easting, coordinates.northing, coordinates.height);

        const sortByDistanceToPosition = (
          a: GeodataImageItemWithMarkers,
          b: GeodataImageItemWithMarkers,
        ) => {
          const coordsA = getPositionFromGeodataImageTransform(a.transform!);
          const coordsB = getPositionFromGeodataImageTransform(b.transform!);
          return (
            new Vector3(coordsA.easting, coordsA.northing, coordsA.height).distanceToSquared(
              position,
            ) -
            new Vector3(coordsB.easting, coordsB.northing, coordsB.height).distanceToSquared(
              position,
            )
          );
        };

        setSortedImageList(
          imagesWithMarkers
            .filter((x) => isDefined(x.imageId) && isDefined(x.transform))
            .sort(sortByDistanceToPosition)
            .map((x) => {
              return {
                imageId: x.imageId!,
              };
            }),
        );
        selectImage(hoveredImage.image);
        setShowPlaceMarkersDialog(true);
        setSavedIntersection(undefined);
      } else if (isDefined(hoveredGcp)) {
        selectGcp(hoveredGcp);
      }
    },
    [
      setShowPlaceMarkersDialog,
      setSortedImageList,
      imagesWithMarkers,
      lastMousePosition,
      selectImage,
      selectGcp,
      hoveredImage,
      hoveredGcp,
    ],
  );

  const onCancelMeasureOrSelectImage = useCallback(() => {
    if (isDefined(savedIntersection)) {
      setSavedIntersection(undefined);
    } else {
      setSavedIntersection(intersection);
    }
  }, [savedIntersection, intersection]);

  const onLeftDown = useCallback(
    (mouseEvent: MouseEvent) => {
      setLastMousePosition(new Vector2(mouseEvent.clientX, mouseEvent.clientY));
    },
    [setLastMousePosition],
  );

  usePointerEvent('pointerdown', pointerRef, 'left', onLeftDown);
  usePointerEvent('pointerup', pointerRef, 'left', onLeftUp);
  usePointerEvent('pointerup', pointerRef, 'right', onCancelMeasureOrSelectImage);

  if (!isDefined(intersection)) {
    return null;
  }

  if (isDefined(distance) && isDefined(savedIntersection)) {
    return (
      <Component client={intersection.mousePosition.client}>
        <Stack spacing={0.2}>
          <span>Distance: {distance.toFixed(3)} m</span>
          <span>
            Planar distance:{' '}
            {intersection.localPosition
              .distanceTo(
                savedIntersection.localPosition.clone().setZ(intersection.localPosition.z),
              )
              .toFixed(3)}{' '}
            m
          </span>
          <span>
            Height difference:{' '}
            {(intersection.localPosition.z - savedIntersection.localPosition.z).toFixed(3)} m
          </span>
        </Stack>
      </Component>
    );
  }

  return (
    <Component client={intersection.mousePosition.client}>
      <Stack spacing={0.2}>
        {hoveredImage && <span>{hoveredImage.image.displayName}</span>}
        {hoveredImage && <img src={hoveredImage.thumbnailUrl} />}
        {hoveredGcp && <span>{hoveredGcp.name}</span>}
        <span>N: {intersection.coordinates.northing.toFixed(3)}</span>
        <span>E: {intersection.coordinates.easting.toFixed(3)}</span>
        <span>H: {intersection.coordinates.height.toFixed(3)}</span>
      </Stack>
    </Component>
  );
};

const Component = styled.div<{ client: { x: number; y: number } }>`
  position: absolute;
  color: white;
  background-color: rgba(0, 0, 0, 0.72);
  border: 1px solid ${({ theme }) => theme.color.gray.darkest};
  left: ${({ client }) => client.x + 10}px;
  top: ${({ client }) => client.y}px;
  padding: 0.5em;
  z-index: ${sfmViewerLayering.tooltip};
  pointer-events: none;
`;
