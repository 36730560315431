import PubSub from 'pubsub-js';
import React from 'react';
import styled from 'styled-components';

import { Button } from '../../button/button';
import { Dialog } from '../../dialog/dialog';
import { InfoBox } from '../../info-box/info-box';
import { ModifiedTimelineContainer } from './manual-uploads';
import { ManualUploadItem } from './skyview-data';

interface Props {
  item: ManualUploadItem;
  timelineContainers: ModifiedTimelineContainer[];
  onClose: (wasDeleted: boolean) => void;
  onDelete: (item: ManualUploadItem) => Promise<void>;
}

enum Status {
  Idle,
  Pending,
  Error,
}

const DeleteSkyViewDataDialog = (props: Props) => {
  const [status, setStatus] = React.useState(Status.Idle);
  const [errorMessage, setErrorMessage] = React.useState<string>();

  const isConnectedToTimeline = (item: ManualUploadItem) => {
    // TODO: This check should be replaced with API-level error
    // handling. When the error is returned it will
    // show the correct error message and notification
    return (
      props.timelineContainers.find(
        (container) =>
          container.gltftScanId === item.id ||
          container.tiledModelId === item.id ||
          container.orthoTileId === item.id ||
          container.pointCloudId === item.id,
      ) !== undefined
    );
  };

  const onDelete = async () => {
    setStatus(Status.Pending);

    if (isConnectedToTimeline(props.item)) {
      PubSub.publish('notify', { code: 'GEOerr008' });
      setErrorMessage(
        'Det geografiska objektet kunde inte raderas. Objektet måste koppla bort från tidslinjen innan den går att radera.',
      );
      setStatus(Status.Error);
      return;
    }

    try {
      await props.onDelete(props.item);
      PubSub.publish('notify', { code: 'succ0503', data: [props.item.name] });
      props.onClose(true);
    } catch (err) {
      PubSub.publish('notify', { code: 'GEOerr009' });
      setErrorMessage('Det geografiska objektet kunde inte raderas.');
      setStatus(Status.Error);
    }
  };

  return (
    <>
      <Dialog
        closeIcon={status !== Status.Pending}
        closeOnDimmerClick={status !== Status.Pending}
        useGoogleTranslate={true}
        width={500}
        onClose={() => props.onClose(false)}
      >
        {{
          header: 'Ta bort',
          content: (
            <Content>
              <Text topMargin={false}>
                Vill du ta bort <b>{props.item.name}</b>?
              </Text>
              <InfoBox color="yellow" leftIcon={{ icon: ['fad', 'exclamation'] }}>
                Observera att åtgärden inte går att ångra.
              </InfoBox>
              {status === Status.Error && (
                <InfoBox color="red">{errorMessage ?? 'Kunde inte ta bort filen.'}</InfoBox>
              )}
            </Content>
          ),
          footer: {
            right: (
              <>
                <Button
                  color="error"
                  disabled={status === Status.Pending}
                  loading={status === Status.Pending}
                  variant="contained"
                  onClick={onDelete}
                >
                  Ta bort
                </Button>

                <Button
                  disabled={status === Status.Pending}
                  variant="text"
                  onClick={() => props.onClose(false)}
                >
                  Stäng
                </Button>
              </>
            ),
          },
        }}
      </Dialog>
    </>
  );
};

const Text = styled.span<{ topMargin?: boolean; bottomMargin?: boolean }>`
  display: block;

  strong {
    font-weight: 600;
  }

  margin-top: ${(props) => (props.topMargin ? '0.5em' : undefined)};
  margin-bottom: ${(props) => (props.bottomMargin ? '0.5em' : undefined)};
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1em;
`;

export { DeleteSkyViewDataDialog };
