import { useMutation } from '@tanstack/react-query';
import { sv } from 'date-fns/locale';
import { t } from 'i18next';
import React, { ReactElement, useRef } from 'react';
import styled from 'styled-components';

import { GeodataModel } from '../../../../../typings/api/skymap/rest/v0/.common';
import { GetGeodataResponse } from '../../../../../typings/api/skymap/rest/v0/geodata';
import { GeodataProcessType } from '../../../../../typings/api/skymap/rest/v1/.common';
import { FeatureFlagsManager } from '../../../../js/core/feature-flags-manager';
import { publish, SubscriptionTopic } from '../../../../js/messaging/pubsub';
import { SkyMapAxiosServiceFactory } from '../../../../js/services/axios/skymap-axios-service-factory';
import { ProjectStore } from '../../../../js/stores/project-store';
import { ThemeManager } from '../../../../js/theming/theme-manager';
import { formattedDate } from '../../../../js/utils/dateUtils';
import OrthoOnlyIcon from '../../../../static/assets/img/cloudprocessing/orthophoto-only.svg';
import PointcloudOnlyIcon from '../../../../static/assets/img/cloudprocessing/pointcloud-only.svg';
import StandardIcon from '../../../../static/assets/img/cloudprocessing/standard.svg';
import { useErrorHandling } from '../../../hooks/use-error-handling';
import { Button } from '../../button/button';
import { Hyperlink } from '../../hyperlink/hyperlink';
import { IconPanel } from '../../icon-panel/icon-panel';
import { OverlayLoader } from '../../overlay-loader/overlay-loader';
import { Stack } from '../../stack/stack';

type Props = {
  onSuccess: (processType: GeodataModel) => void;
};

type CardProps = {
  onClick: (processType: GeodataProcessType) => void;
};

const useCreatePendingGeodataCallback = (
  projectId: string,
  name: string,
  onSuccess?: (data: GeodataModel) => void,
) => {
  const { buildErrorList, handleError } = useErrorHandling({ displayNonApiErrors: false });

  const mutation = useMutation<GetGeodataResponse, Error, GeodataProcessType>({
    mutationFn: async (processType) => {
      return SkyMapAxiosServiceFactory.instance.createGeodataServiceV0().createPendingGeodata({
        body: {
          projectId,
          name,
          processType,
        },
      });
    },
    onSuccess: (response) => {
      onSuccess?.(response.data);
      publish(SubscriptionTopic.ToastrMessage, {
        type: 'success',
        message: t('setupDialog.successfullyCreatedGeodata', { ns: 'cloudProcessing' }),
      });
    },
    onError: (error) => handleError(error),
    networkMode: 'always',
  });

  return {
    createPendingGeodata: mutation.mutate,
    isPending: mutation.isPending,
    buildErrorList,
  };
};

/**
 * Gets the default name for the cloud process.
 * Note: This always uses Swedish locale since other locales will sometimes generate illegal characters.
 */
function getDefaultName() {
  return formattedDate(Date.now(), sv);
}

export const CreatePendingGeodata = (props: Props) => {
  const project = useRef(ProjectStore.instance.project!);
  const { createPendingGeodata, isPending, buildErrorList } = useCreatePendingGeodataCallback(
    project.current.id,
    getDefaultName(),
    props.onSuccess,
  );

  return (
    <OverlayLoader visible={isPending}>
      <Stack spacing={1}>
        <ProcessingCardsContainer direction="row" spacing={1.2}>
          <Standard onClick={createPendingGeodata} />
          <PointcloudOnly onClick={createPendingGeodata} />
          <OrthoOnly onClick={createPendingGeodata} />
          <Tif onClick={createPendingGeodata} />
          <Premium onClick={createPendingGeodata} />
          {FeatureFlagsManager.instance.isEnabled('SK-5443-beta-pointcloud-pipeline') && (
            <Odm onClick={createPendingGeodata} />
          )}
        </ProcessingCardsContainer>

        <Hyperlink target="_blank" url={ThemeManager.instance.getStandardPriceListUrl()}>
          {t('setupDialog.standardPriceListHyperlink', { ns: 'cloudProcessing' })}
        </Hyperlink>

        {buildErrorList()}
      </Stack>
    </OverlayLoader>
  );
};

const Standard = (props: CardProps) => {
  return (
    <Card
      info={t('setupDialog.cards.standard.text', { ns: 'cloudProcessing' })}
      primary={true}
      title={t('setupDialog.cards.standard.title', { ns: 'cloudProcessing' })}
      onClick={() => props.onClick('FULL')}
    />
  );
};

const Premium = (props: CardProps) => {
  return (
    <Card
      info={t('setupDialog.cards.premium.text', { ns: 'cloudProcessing' })}
      title={t('setupDialog.cards.premium.title', { ns: 'cloudProcessing' })}
      onClick={() => props.onClick('FULL_HIGH_QUALITY')}
    />
  );
};

const Odm = (props: CardProps) => {
  if (!FeatureFlagsManager.instance.isEnabled('SK-5443-beta-pointcloud-pipeline')) {
    return null;
  }

  return (
    <Card
      info={t('setupDialog.cards.odm.text', { ns: 'cloudProcessing' })}
      title={t('setupDialog.cards.odm.title', { ns: 'cloudProcessing' })}
      onClick={() => props.onClick('ODM')}
    />
  );
};

const PointcloudOnly = (props: CardProps) => {
  return (
    <Card
      icon={<PointcloudOnlyIcon />}
      info={t('setupDialog.cards.pointCloud.text', { ns: 'cloudProcessing' })}
      title={t('setupDialog.cards.pointCloud.title', { ns: 'cloudProcessing' })}
      onClick={() => props.onClick('POINTCLOUD_ONLY')}
    />
  );
};

const OrthoOnly = (props: CardProps) => {
  return (
    <Card
      icon={<OrthoOnlyIcon />}
      info={t('setupDialog.cards.orthophoto.text', { ns: 'cloudProcessing' })}
      title={t('setupDialog.cards.orthophoto.title', { ns: 'cloudProcessing' })}
      onClick={() => props.onClick('ORTHO_ONLY')}
    />
  );
};

const Tif = (props: CardProps) => {
  if (!FeatureFlagsManager.instance.isEnabled('SK-4170-orthophoto-generation-from-tif')) {
    return null;
  }

  return (
    <Card
      icon={<OrthoOnlyIcon />}
      info={t('setupDialog.cards.tif.text', { ns: 'cloudProcessing' })}
      title={t('setupDialog.cards.tif.title', { ns: 'cloudProcessing' })}
      onClick={() => props.onClick('ORTHO_TIF')}
    />
  );
};

const Card = (props: {
  icon?: ReactElement;
  title: string;
  info: string;
  primary?: boolean;
  onClick: () => void;
}) => {
  return (
    <ProcessingCard spacing={0.5}>
      <IconPanel
        alignContent="start"
        header={props.icon ?? <StandardIcon />}
        responsiveness={{ compressed: false }}
      >
        {{
          title: props.title,
          info: <Flex>{props.info}</Flex>,
        }}
      </IconPanel>
      <Button
        color={props.primary ? 'primary' : 'secondary'}
        variant="contained"
        onClick={props.onClick}
      >
        {t('select', { ns: 'common' })}
      </Button>
    </ProcessingCard>
  );
};

const ProcessingCardsContainer = styled(Stack)`
  overflow-x: auto;
  overflow-y: hidden;
  padding: 0.2em 1.2em;
`;

const Flex = styled.div`
  flex: 1;
`;

const ProcessingCard = styled(Stack)`
  padding: 1em;
  width: calc(140px + 2em); // Width needs to account for SVG element width and padding.

  &:hover {
    background-color: ${({ theme }) => theme.color.gray.lightest};
  }
`;
