import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { SubscriptionTopic } from '../../../../../../js/messaging/pubsub';
import { assertNever, isDefined } from '../../../../../../js/utils/variables';
import { CellGridVolume } from '../../../../../../js/viewer/elements/volume/cell-grid-volume';
import { PrismVolume } from '../../../../../../js/viewer/elements/volume/prism-volume';
import { VolumeCalculationAsset } from '../../../../../../js/viewer/elements/volume/types';
import { InputHandler } from '../../../../../../js/viewer/input-handler';
import { VolumeInspectorTool } from '../../../../../../js/viewer/tools/volume-inspector-tool';
import { useDialog } from '../../../../../hooks/use-dialog';
import { useNonce } from '../../../../../hooks/use-nonce';
import { useSharedView } from '../../../../../hooks/use-shared-view';
import { useSubscribe } from '../../../../../hooks/use-subscribe';
import { ConfirmationDialog } from '../../../../confirmation-dialog/confirmation-dialog';
import { Icon } from '../../../../icon/icon';
import { InfoBox } from '../../../../info-box/info-box';
import { Select } from '../../../../select/select';
import { Stack } from '../../../../stack/stack';
import { DeleteIcon } from '../../icons/delete-icon';
import { FullWidth } from '../../styles/grid';
import { AssetVolumeSelected } from './asset-volume-selected';

type Props = {
  asset: VolumeCalculationAsset;
};

const AssetVolumes = (props: Props) => {
  const { t } = useTranslation();
  const deleteDialog = useDialog();
  const [refreshNonce, refresh] = useNonce();
  const { isSharedView, sharedViewId } = useSharedView();
  const [visible, setVisible] = React.useState(false);
  const [selectedVolume, setSelectedVolume] = React.useState<
    CellGridVolume | PrismVolume | undefined
  >();

  // Refresh component when tool is changed.
  // This makes "show cut and fill" button enabled when right panel is closed.
  useSubscribe(SubscriptionTopic.SetTool, refresh);

  React.useEffect(() => {
    if (!selectedVolume || selectedVolume !== props.asset.volumeHandler.activeVolume) {
      const activeVolume =
        props.asset.volumeHandler.activeVolume ??
        (props.asset.cellGridVolumes.length > 0 ? props.asset.cellGridVolumes[0] : undefined) ??
        (props.asset.prismVolumes.length > 0 ? props.asset.prismVolumes[0] : undefined);

      // When a volume is deleted, activeVolume is set to undefined. When undefined and another
      // volume is selected, update volume handler with the active volume and refresh component.
      setSelectedVolume(activeVolume);
      if (!props.asset.volumeHandler.activeVolume) {
        props.asset.volumeHandler.activeVolume = activeVolume;
        refresh();
      }
    }
  }, [
    props.asset.cellGridVolumes,
    props.asset.prismVolumes,
    props.asset.volumeHandler,
    props.asset.volumeHandler.activeVolume,
    refresh,
    selectedVolume,
  ]);

  // Update visibility whenever visible of asset changes.
  React.useEffect(() => {
    setVisible(props.asset.visible && props.asset.volumeHandler.isModelVisible());
  }, [refreshNonce, props.asset.volumeHandler, props.asset.visible]);

  useSubscribe(SubscriptionTopic.AssetChanged, () => {
    setSelectedVolume(props.asset.volumeHandler.activeVolume);
  });

  useSubscribe(SubscriptionTopic.ModelVolumeStateChanged, () => {
    refresh();

    if (
      props.asset.volumeHandler.getStatus() === 'FAILED' ||
      !(props.asset.volumeHandler.activeVolume instanceof CellGridVolume)
    ) {
      if (InputHandler.instance.currentTool() instanceof VolumeInspectorTool) {
        InputHandler.instance.setTool('select');
      }
    }
  });

  const isModelLoading = props.asset.volumeHandler.isModelLoading();

  const allVolumes = [...props.asset.cellGridVolumes, ...props.asset.prismVolumes];

  const renderVolumesExist = () => (
    <Stack direction="column" spacing={1.2}>
      <FullWidth>
        <Select
          disabled={isModelLoading}
          options={allVolumes}
          value={selectedVolume!.id}
          onChange={async (e) => {
            const selectedVolume = [
              ...props.asset.cellGridVolumes,
              ...props.asset.prismVolumes,
            ].find((x) => x.id === e.target.value);

            props.asset.volumeHandler.activeVolume = selectedVolume;
            await props.asset.volumeHandler.refreshVolume(sharedViewId);

            setSelectedVolume(selectedVolume);
          }}
        />
      </FullWidth>

      <AssetVolumeSelected
        asset={props.asset}
        enabled={visible}
        volume={selectedVolume!}
        onDeleted={() => setVisible(false)}
      />
    </Stack>
  );

  const renderNoVolumesExist = () => (
    <InfoBox color="yellow" leftIcon={{ icon: ['fad', 'info'] }}>
      <Trans
        components={{ icon: <Icon icon={['fal', 'calculator']} /> }}
        i18nKey="volumes.none"
        ns="skyviewAssetMenu"
      />
    </InfoBox>
  );

  const getHiddenTitle = () => {
    switch (props.asset.assetType) {
      case 'area':
        return t('volumes.icons.areaHidden', { ns: 'skyviewAssetMenu' });
      case 'comment':
      case 'poi':
        return t('volumes.icons.commentHidden', { ns: 'skyviewAssetMenu' });
      case 'design':
        return t('volumes.icons.designHidden', { ns: 'skyviewAssetMenu' });
      case 'dxf':
      case 'glb':
        return t('volumes.icons.cadHidden', { ns: 'skyviewAssetMenu' });
      case 'line':
        return t('volumes.icons.lineHidden', { ns: 'skyviewAssetMenu' });
      case 'model':
        return t('volumes.icons.modelHidden', { ns: 'skyviewAssetMenu' });
      case 'terrain-model':
        return t('volumes.icons.terrainModelHidden', { ns: 'skyviewAssetMenu' });
      default:
        assertNever(props.asset.assetType);
    }
  };

  // Don't display component in shared view if there are no volumes to display.
  if (isSharedView && allVolumes.length === 0) {
    return <></>;
  }

  const showDisplayIcon = isSharedView
    ? isDefined(props.asset.volumeHandler.activeVolume) &&
      props.asset.volumeHandler.activeVolume instanceof CellGridVolume
    : isDefined(props.asset.volumeHandler.activeVolume);

  return (
    <>
      <Stack direction="column" spacing={0.5}>
        <VolumeHeader>
          <Stack direction="row" justifyContent="space-between" spacing={0.5}>
            <Title>{t('volumes.title', { ns: 'skyviewAssetMenu' })}</Title>
            <Stack direction="row" spacing={0.5}>
              {allVolumes.length > 0 && !isSharedView && (
                <DeleteIcon
                  onClick={(e) => {
                    deleteDialog.show({ x: e.clientX, y: e.clientY });
                  }}
                />
              )}
              {showDisplayIcon && (
                <Icon
                  disabled={!props.asset.visible || isModelLoading}
                  icon={['fal', isModelLoading ? 'spinner' : visible ? 'eye' : 'eye-slash']}
                  spin={isModelLoading}
                  title={
                    props.asset.visible
                      ? visible
                        ? t('hide', { ns: 'common' })
                        : t('show', { ns: 'common' })
                      : getHiddenTitle()
                  }
                  onClick={async () => {
                    await props.asset.volumeHandler.toggleModelVisibility(sharedViewId);
                    setVisible(props.asset.volumeHandler.isModelVisible());
                  }}
                  onHoverStyle={{
                    icon: ['fad', isModelLoading ? 'spinner' : visible ? 'eye' : 'eye-slash'],
                  }}
                />
              )}
            </Stack>
          </Stack>
        </VolumeHeader>

        {selectedVolume ? renderVolumesExist() : renderNoVolumesExist()}
      </Stack>

      {deleteDialog.render((pos) => (
        <ConfirmationDialog
          confirmationText={t('common.deleteAssetVolumeDialog.confirmButton', {
            ns: 'skyviewAssetMenu',
          })}
          negative={true}
          pos={pos}
          title={t('common.deleteAssetVolumeDialog.title', { ns: 'skyviewAssetMenu' })}
          onCancel={deleteDialog.hide}
          onConfirm={async () => {
            await props.asset.volumeHandler.delete();
            setSelectedVolume(undefined);
            deleteDialog.hide();
          }}
        >
          <Stack direction="column" spacing={1}>
            <span>
              <Trans
                components={{ bold: <strong /> }}
                i18nKey="common.deleteAssetVolumeDialog.text"
                ns="skyviewAssetMenu"
                values={{ assetVolumeName: selectedVolume?.name ?? '<Unknown>' }}
              />
            </span>
            <InfoBox color="red" leftIcon={{ icon: ['fad', 'exclamation'] }}>
              {t('common.deleteAssetDialog.warning', { ns: 'skyviewAssetMenu' })}
            </InfoBox>
          </Stack>
        </ConfirmationDialog>
      ))}
    </>
  );
};

const VolumeHeader = styled(FullWidth)`
  background-color: #eee;
  padding: 0.5em;
`;

const Title = styled.span`
  text-transform: uppercase;
`;

export { AssetVolumes };
