import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import { ProfileModel } from '../../../../../typings/api/skymap/rest/v1/profile';
import { SkyMapAxiosServiceFactory } from '../../../../js/services/axios/skymap-axios-service-factory';
import { t } from '../../../../js/utils/i18-next';
import MicrosoftLogoImage from '../../../../static/Microsoft_logo.svg';
import { ProfileLoadingState, useProfileContext } from '../../../state/profile-state';
import { Button } from '../../button/button';
import { Icon } from '../../icon/icon';
import { Stack } from '../../stack/stack';
import { PageProperty } from '../page-property';
import { DisconnectSsoDialog } from './disconnect-sso-form';

type Props = {
  profile?: ProfileModel;
};

const AuthenticationAndSecurity = (props: Props) => {
  const { reloadProfile, state } = useProfileContext();
  const [showDisconnectSsoDialog, setShowDisconnectSsoDialog] = useState(false);

  const [isLoadingProfile, setIsLoadingProfile] = useState(state === ProfileLoadingState.Loading);

  const authenticationFlowType = props.profile?.authenticationFlow.authFlowType;

  useEffect(() => {
    setIsLoadingProfile(state === ProfileLoadingState.Loading);
  }, [state, setIsLoadingProfile]);

  /* 
    Enabling SSO might trigger an OAuth flow, etc. 
  */
  const handleEnableSSO = () => {
    const authV1Service = SkyMapAxiosServiceFactory.instance.createAuthServiceV1();
    window.location.href = authV1Service.getMicrosoftRedirectUrl({
      query: { userId: props.profile?.id },
    });
  };

  /* 
    Disabling SSO might require user to set a new password 
    or confirm an existing password. 
  */
  const handleDisableSSO = () => {
    setShowDisconnectSsoDialog(true);
  };

  const onDisconnectSsoDialogClosed = React.useCallback(
    (successfullyUpdatedAuthenticationFlow: boolean) => {
      setShowDisconnectSsoDialog(false);
      if (successfullyUpdatedAuthenticationFlow) {
        reloadProfile();
      }
    },
    [setShowDisconnectSsoDialog, reloadProfile],
  );

  const resolveActiveAuthenticationIcon = () => (
    <ActiveAuthenticationFlow alignItems="center" direction="row" spacing={'0.5em'}>
      {authenticationFlowType === 'PASSWORD' ? (
        <Icon icon={['fad', 'key']} size="xl" />
      ) : (
        <StyledMicrosoftLogoWrapper>
          <MicrosoftLogoImage />
        </StyledMicrosoftLogoWrapper>
      )}
      {authenticationFlowType === 'PASSWORD' ? t('password', { ns: 'profile' }) : 'Microsoft'}
    </ActiveAuthenticationFlow>
  );

  const resolveSsoButtonText = () =>
    authenticationFlowType === 'PASSWORD'
      ? t('sections.authentication.properties.enableSSO', { ns: 'profile' })
      : t('sections.authentication.properties.disableSSO', { ns: 'profile' });

  return (
    <AuthContainer {...props} spacing={'0.5em'}>
      <Stack direction="row">
        <PageProperty
          isLoading={isLoadingProfile}
          title={t('sections.authentication.properties.authenticationMethod', { ns: 'profile' })}
          value={props.profile && resolveActiveAuthenticationIcon()}
        />
      </Stack>
      {!isLoadingProfile && (
        <Stack alignItems="end" direction="row-reverse">
          <Button
            alignSelf="end"
            color={'primary'}
            variant={'contained'}
            onClick={authenticationFlowType === 'PASSWORD' ? handleEnableSSO : handleDisableSSO}
          >
            {resolveSsoButtonText()}
          </Button>
        </Stack>
      )}

      {showDisconnectSsoDialog && <DisconnectSsoDialog onClose={onDisconnectSsoDialogClosed} />}
    </AuthContainer>
  );
};

const AuthContainer = styled(Stack)`
  width: 100%;
`;

const ActiveAuthenticationFlow = styled(Stack)`
  background-color: ${(props) => props.theme.color.gray.lightest};
  box-shadow: 0 1px 5px ${(props) => props.theme.color.gray.medium};

  padding: 0.5em;
  border-radius: 8%;

  max-width: fit-content;
`;

const StyledMicrosoftLogoWrapper = styled.div`
  margin-bottom: -4px;
  > svg {
    width: 1.5em;
    height: 1.5em;
    margin-right: 8px;
  }
`;

export { AuthenticationAndSecurity };
