import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { AuthenticationFlowType } from '../../../../typings/api/skymap/rest/v1/.common';
import { iiafe } from '../../../../utilities/async';
import { FeatureFlagsManager } from '../../../js/core/feature-flags-manager';
import { SkyMapAxiosServiceFactory } from '../../../js/services/axios/skymap-axios-service-factory';
import { ThemeManager } from '../../../js/theming/theme-manager';
import { SupportedLanguage } from '../../../js/utils/text/translation';
import { emailRegex } from '../../../js/utils/text/validation';
import { isDefined } from '../../../js/utils/variables';
import { authHook } from '../../hooks/use-auth';
import { useLanguage } from '../../hooks/use-language';
import { useRouter } from '../../hooks/use-router';
import { reset } from '../../utils/styled-reset';
import { Button } from '../button/button';
import { HyperlinkStyled } from '../hyperlink/hyperlink';
import { InfoBox } from '../info-box/info-box';
import { LabelledContainer } from '../labelled-container/labelled-container';
import { Select } from '../select/select';
import { Stack } from '../stack/stack';
import { TextBox } from '../text-box/text-box';
import { useAuthenticationFlowQuery } from './use-authentication-flow-query';

export type Form = {
  language: string;
  email: string;
  password: string;
};

const SignInForm = () => {
  const { languageItems, selectLanguage, selectedLanguage, selectDefaultLanguage } = useLanguage();
  const { t } = useTranslation();
  const { saveToken } = authHook.useAuth();
  const { redirectToAngularState } = useRouter();

  // The login page should set either the default or cookie language.
  React.useEffect(() => {
    void selectDefaultLanguage();
  }, [selectDefaultLanguage]);

  const ssoFlagIsActive = FeatureFlagsManager.instance.isEnabled('SK-5925-SSO');

  const [flowType, setFlowType] = React.useState<AuthenticationFlowType | null>(
    ssoFlagIsActive ? null : 'PASSWORD',
  );

  const {
    formState: { isValid, errors },
    control,
    handleSubmit,
    register,
    reset: resetForm,
    setError,
    watch,
  } = useForm<Form>({
    defaultValues: {
      language: selectedLanguage.value,
      email: '',
      password: '',
    },

    shouldUnregister: true,
  });

  const redirectToMicrosoftSignIn = () => {
    const authV1Service = SkyMapAxiosServiceFactory.instance.createAuthServiceV1();

    const url = authV1Service.getMicrosoftRedirectUrl({ query: { email: watch('email') } });
    window.location.href = url;
  };

  // Our merged hook with two mutations:
  const {
    determineAuthFlowMutation,
    signInMutation,
    hasError,
    buildErrorList,
    getErrorForPath,
    excludeErrorPaths,
  } = useAuthenticationFlowQuery({
    onSuccessDetermineFlow: ({ data }) => {
      if (data.authenticationFlow === 'PASSWORD') {
        setFlowType('PASSWORD');
      } else if (data.authenticationFlow === 'MICROSOFT_ENTRA_ID') {
        redirectToMicrosoftSignIn();
      }
    },
    /**
     * Called when `signInMutation` succeeds:
     *  - Save token, redirect, etc.
     */
    onSuccessSignIn: async ({ data }) => {
      await saveToken(data.token);
      await redirectToAngularState('redirect');
      resetForm();
    },
  });

  // If the backend returned general errors for `email` or `password`, set them here.
  React.useEffect(() => {
    setError('email', { type: 'custom', message: getErrorForPath('body.email') });
    setError('password', { type: 'custom', message: getErrorForPath('body.password') });
  }, [getErrorForPath, setError]);

  // Exclude default errors for "email" & "password" since we handle them above
  const excludeDefaultErrors = excludeErrorPaths(['body.email', 'body.password']);

  // Calls "determineAuthFlow" only with email.
  const onSubmitDetermineAuthenticationFlow = (formData: Form) => {
    determineAuthFlowMutation.mutate(formData.email);
  };

  // Calls "signIn" with email+password.
  const onSubmitSignIn = (formData: Form) => {
    signInMutation.mutate(formData);
  };

  const isSubmitting = determineAuthFlowMutation.isPending || signInMutation.isPending;

  return (
    <Component>
      <h1>{t('signInForm.title', { ns: 'components' })}</h1>

      <form
        onSubmit={
          !isDefined(flowType)
            ? handleSubmit(onSubmitDetermineAuthenticationFlow)
            : handleSubmit(onSubmitSignIn)
        }
      >
        <Stack spacing={1}>
          {/* Language selection: always visible */}
          <LabelledContainer text={t('signInForm.form.language', { ns: 'components' })}>
            {(formElementId) => {
              const id = formElementId();
              return (
                <Controller
                  control={control}
                  name="language"
                  render={({ field }) => (
                    <Select
                      {...field}
                      disabled={isSubmitting}
                      errorMessage={errors.language?.message}
                      id={id}
                      options={languageItems.map((x) => ({
                        id: x.value,
                        name: x.text,
                      }))}
                      onChange={(e) => {
                        field.onChange(e);
                        iiafe(async () => {
                          await selectLanguage(e.target.value as SupportedLanguage);
                        });
                      }}
                    />
                  )}
                />
              );
            }}
          </LabelledContainer>

          <LabelledContainer
            required={true}
            text={t('signInForm.form.email', { ns: 'components' })}
          >
            {(formElementId) => (
              <TextBox
                {...register('email', {
                  required: true,
                  pattern: {
                    value: emailRegex,
                    message: t('signInForm.emailInvalidMessage', { ns: 'components' }),
                  },
                })}
                autoFocus={true}
                disabled={(ssoFlagIsActive && isDefined(flowType)) || isSubmitting}
                enforceCase="lowercase"
                errorMessage={errors.email?.message}
                id={formElementId()}
              />
            )}
          </LabelledContainer>

          <StyledLabelledContainer
            required={!ssoFlagIsActive || isDefined(flowType)}
            text={t('signInForm.form.password', { ns: 'components' })}
            visible={(!ssoFlagIsActive || isDefined(flowType)) && flowType === 'PASSWORD'}
          >
            {(formElementId) => (
              <TextBox
                {...register('password', { required: !ssoFlagIsActive || isDefined(flowType) })}
                disabled={isSubmitting}
                errorMessage={errors.password?.message}
                id={formElementId()}
                type="password"
              />
            )}
          </StyledLabelledContainer>

          {/* If there are other errors (not email/password), show them */}
          {hasError(excludeDefaultErrors) && buildErrorList({ filter: excludeDefaultErrors })}

          {ThemeManager.instance.getThemeName() !== 'moba' && selectedLanguage.value !== 'sv' && (
            <InfoBox color="yellow" leftIcon={{ icon: ['fad', 'info'] }} topMargin={true}>
              {`Note that all features currently might not work as expected when you select another language than swedish.`}
            </InfoBox>
          )}

          {ssoFlagIsActive && flowType === 'PASSWORD' && (
            <>
              <Stack direction="column" spacing={1}>
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  margin="1em 0 0 0"
                  spacing={0.25}
                >
                  <Button
                    color="secondary"
                    loading={signInMutation.isPending}
                    type="button"
                    variant="contained"
                    onClick={() => setFlowType(null)}
                  >
                    {t('signInForm.backButton', { ns: 'components' })}
                  </Button>
                  <Button
                    autoFocus={true}
                    color="primary"
                    loading={signInMutation.isPending}
                    type="submit"
                    variant="contained"
                  >
                    {t('signInForm.loginButton', { ns: 'components' })}
                  </Button>
                </Stack>
                <HyperlinkStyled
                  onClick={
                    isSubmitting ? undefined : () => redirectToAngularState('requestpasswordchange')
                  }
                >
                  {t('signInForm.forgotPasswordButton', { ns: 'components' })}
                </HyperlinkStyled>
              </Stack>
            </>
          )}

          {!ssoFlagIsActive && (
            <Stack direction="row" justifyContent="space-between" margin="1em 0 0 0" spacing={0.25}>
              <Button
                color="secondary"
                disabled={isSubmitting}
                type="button"
                variant="text"
                onClick={() => redirectToAngularState('requestpasswordchange')}
              >
                {t('signInForm.forgotPasswordButton', { ns: 'components' })}
              </Button>
              <Button
                color="primary"
                loading={signInMutation.isPending}
                type="submit"
                variant="contained"
              >
                {t('signInForm.loginButton', { ns: 'components' })}
              </Button>
            </Stack>
          )}

          {ssoFlagIsActive && !isDefined(flowType) && (
            <Stack direction="row" justifyContent="flex-end" margin="1em 0 0 0">
              <Button
                autoFocus={true}
                color="primary"
                disabled={!isValid}
                loading={determineAuthFlowMutation.isPending}
                type="submit"
                variant="contained"
                width={'100%'}
              >
                {t('continue', { ns: 'common' })}
              </Button>
            </Stack>
          )}
        </Stack>
      </form>
    </Component>
  );
};

const StyledLabelledContainer = styled(LabelledContainer)<{ visible: boolean }>`
  display: ${(props) => (props.visible ? 'block' : 'none')};
`;

const Component = styled.div`
  ${reset}
  font-size: 14px;

  h1 {
    font-size: 1.2em;
    font-weight: 500;
    margin-bottom: 1em;
  }
`;

export { SignInForm };
