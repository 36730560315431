import React from 'react';
import styled from 'styled-components';

import {
  OrthoTileStatus,
  PointCloudStatus,
} from '../../../../../typings/api/skymap/rest/v0/.common';
import {
  GetProjectOrthoTilesResponse,
  GetProjectPointCloudsResponse,
  GetProjectScansResponse,
} from '../../../../../typings/api/skymap/rest/v0/project';
import {
  ProjectModel,
  TiledModel,
  TiledModelStatus,
} from '../../../../../typings/api/skymap/rest/v1/.common';
import { SkyMapAxiosServiceFactory } from '../../../../js/services/axios/skymap-axios-service-factory';
import { formattedDateTime } from '../../../../js/utils/dateUtils';
import { TimelineContainer } from '../../../../js/viewer/timeline-manager';
import { useNonce } from '../../../hooks/use-nonce';
import { Center } from '../../../styles/center';
import { reset } from '../../../utils/styled-reset';
import { Button } from '../../button/button';
import { ManualUploadItem, ManualUploadParams, SkyViewData } from './skyview-data';

interface Props {
  project: ProjectModel;
}

enum Status {
  Pending,
  Error,
  Success,
}

export interface ModifiedTimelineContainer {
  gltftScanId?: string;
  tiledModelId?: string;
  orthoTileId?: string;
  pointCloudId?: string;
}

function translateStatus(status: TiledModelStatus | PointCloudStatus | OrthoTileStatus) {
  switch (status) {
    case 'COMPLETED':
      return 'Klar';
    case 'FAILED':
      return 'Misslyckad';
    default:
      return 'Processerar...';
  }
}

const ManualUploads = (props: Props) => {
  const [status, setStatus] = React.useState<Status>(Status.Pending);

  const [tiledModels, setTiledModels] = React.useState<ManualUploadItem[]>([]);
  const [orthoTiles, setOrthoTiles] = React.useState<ManualUploadItem[]>([]);
  const [pointClouds, setPointClouds] = React.useState<ManualUploadItem[]>([]);
  const [timelineContainers, setTimelineContainers] = React.useState<ModifiedTimelineContainer[]>(
    [],
  );

  const [refreshNonce, setRefreshNonce] = useNonce();

  const loadTimelines = React.useCallback(
    (loadedProject: ProjectModel & { timelineContainers?: TimelineContainer[] }) => {
      // TODO: This is only used so that we can display the correct
      // message to the user when they try to delete an artifact that
      // is connected to a timeline.
      // Instead of passing in timelines to all the components
      // it would be better to handle this error in the backend and
      // show the custom error to the user when it fails in backend.
      setTimelineContainers(
        (loadedProject.timelineContainers ?? []) as React.SetStateAction<
          ModifiedTimelineContainer[]
        >,
      );
    },
    [],
  );

  const loadTiledModels = React.useCallback(async () => {
    const scansResponse = await SkyMapAxiosServiceFactory.instance
      .createProjectServiceV0()
      .getScans({
        path: { projectId: props.project.id },
      });
    const tiledModelsResponse = await SkyMapAxiosServiceFactory.instance
      .createProjectServiceV1()
      .getTiledModels({
        path: { projectId: props.project.id },
      });

    const mapScans = (scan: GetProjectScansResponse['data'][0]): ManualUploadItem => {
      return {
        id: scan.id,
        name: scan.fileName,
        status: scan.size > 0 ? 'Klar' : 'Processerar',
        uploaded: new Date(scan.uploaded),
        isOldScan: true,
      };
    };

    const mapTiledModel = (tiledModel: TiledModel): ManualUploadItem => {
      return {
        id: tiledModel.id,
        name: tiledModel.name,
        status: translateStatus(tiledModel.status),
        uploaded: new Date(tiledModel.createdAt),
      };
    };

    setTiledModels(
      [...tiledModelsResponse.data.map(mapTiledModel), ...scansResponse.data.map(mapScans)].sort(
        (a, b) => {
          return b.uploaded.getTime() - a.uploaded.getTime();
        },
      ),
    );
  }, [props.project.id]);

  const loadPointClouds = React.useCallback(async () => {
    const response = await SkyMapAxiosServiceFactory.instance
      .createProjectServiceV0()
      .getPointClouds({
        path: { projectId: props.project.id },
      });

    const mapPointCloud = (item: GetProjectPointCloudsResponse['data'][0]): ManualUploadItem => {
      return {
        id: item.id,
        name: item.fileName,
        status: translateStatus(item.status),
        uploaded: new Date(item.uploadDate),
      };
    };

    setPointClouds(
      response.data.map(mapPointCloud).sort((a, b) => {
        return b.uploaded.getTime() - a.uploaded.getTime();
      }),
    );
  }, [props.project.id]);

  const loadOrthoTiles = React.useCallback(async () => {
    const response = await SkyMapAxiosServiceFactory.instance
      .createProjectServiceV0()
      .getOrthoTiles({
        path: { projectId: props.project.id },
      });

    const mapOrthoTile = (item: GetProjectOrthoTilesResponse['data'][0]): ManualUploadItem => {
      return {
        id: item.id,
        name: item.fileName ?? `Ortofoto ${formattedDateTime(new Date(item.uploaded))}`,
        status: translateStatus(item.status),
        uploaded: new Date(item.uploaded),
      };
    };

    setOrthoTiles(
      response.data.map(mapOrthoTile).sort((a, b) => {
        return b.uploaded.getTime() - a.uploaded.getTime();
      }),
    );
  }, [props.project.id]);

  React.useEffect(() => {
    const load = async () => {
      setStatus(Status.Pending);

      try {
        await loadTiledModels();
        await loadPointClouds();
        await loadOrthoTiles();

        setStatus(Status.Success);
      } catch (err) {
        setStatus(Status.Error);
      }
    };

    void load();
  }, [
    refreshNonce,
    props.project.id,
    loadTiledModels,
    loadPointClouds,
    loadOrthoTiles,
    loadTimelines,
  ]);

  if (status === Status.Error) {
    return (
      <StyledError>
        <h3>Oväntat fel</h3>
        <span>Uppladdningarna för projektet kunde inte läsas in.</span>
        <Button color="primary" variant="contained" onClick={() => setRefreshNonce()}>
          Försök igen
        </Button>
      </StyledError>
    );
  }

  if (status === Status.Pending) {
    return <Center>Ett ögonblick...</Center>;
  }

  const onCesium3dTilesUploaded = async ({ filename, uploadId, isZUp }: ManualUploadParams) => {
    const result = await SkyMapAxiosServiceFactory.instance.createScanServiceV0().createGltftScan({
      body: { uploadId, projectId: props.project.id, isZUp },
    });

    return {
      id: result.id,
      name: filename,
      status: 'Processerar',
      uploaded: new Date(),
    };
  };

  const onCesium3dTilesDelete = async (item: ManualUploadItem) => {
    if (item.isOldScan) {
      await SkyMapAxiosServiceFactory.instance
        .createScanServiceV0()
        .delete({ body: { gltftScanId: item.id } });
    }

    await SkyMapAxiosServiceFactory.instance
      .createTiledModelServiceV1()
      .deleteTiledModel({ path: { tiledModelId: item.id } });
  };

  const onOrthoTileUploaded = async ({
    filename,
    uploadId,
    coordinateSystemId,
  }: ManualUploadParams) => {
    const result = await SkyMapAxiosServiceFactory.instance
      .createOrthoTileServiceV0()
      .createOrthotile({
        body: {
          uploadId,
          projectId: props.project.id,
          coordinateSystemId,
        },
      });

    return {
      id: result.id,
      name: filename,
      status: 'Processerar',
      uploaded: new Date(),
    };
  };

  const onOrthoTileDelete = async (item: ManualUploadItem) => {
    await SkyMapAxiosServiceFactory.instance
      .createOrthoTileServiceV0()
      .delete({ body: { orthotileId: item.id } });
  };

  const onPointCloudUploaded = async ({
    filename,
    uploadId,
    coordinateSystemId,
    geoidId,
  }: ManualUploadParams) => {
    const result = await SkyMapAxiosServiceFactory.instance
      .createPointCloudServiceV0()
      .createPointCloud({
        body: {
          uploadId,
          projectId: props.project.id,
          coordinateSystemId,
          geoidId,
        },
      });

    return {
      id: result.id,
      name: filename,
      status: 'Processerar',
      uploaded: new Date(),
    };
  };

  const onPointCloudDelete = async (item: ManualUploadItem) => {
    await SkyMapAxiosServiceFactory.instance
      .createPointCloudServiceV0()
      .delete({ path: { id: item.id } });
  };

  return (
    <Component>
      <SkyViewData
        accept=".zip,.3tz"
        items={tiledModels}
        showZUpCheckBox={true}
        timelineContainers={timelineContainers}
        title="Avbildning (Cesium 3D-tiles) (.zip, .3tz)"
        onDelete={onCesium3dTilesDelete}
        onUploaded={onCesium3dTilesUploaded}
      />
      <SkyViewData
        accept=".laz,.las,.zip"
        items={pointClouds}
        showCoordinateSystem={true}
        showGeoids={true}
        timelineContainers={timelineContainers}
        title="Punktmoln (.laz, .las, .zip)"
        onDelete={onPointCloudDelete}
        onUploaded={onPointCloudUploaded}
      />
      <SkyViewData
        accept=".zip,.tif"
        items={orthoTiles}
        showCoordinateSystem={true}
        timelineContainers={timelineContainers}
        title="Ortofoto (.zip, .tif)"
        onDelete={onOrthoTileDelete}
        onUploaded={onOrthoTileUploaded}
      />
    </Component>
  );
};

const Component = styled.div`
  display: flex;
  justify-content: space-between;
`;

const StyledError = styled.div`
  ${reset}
  font-size: 14px;

  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5em;
`;

export { ManualUploads };
