import useResizeObserver from '@react-hook/resize-observer';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import styled from 'styled-components';
import _ from 'underscore';

import NoContentImage from '../../../../../www/static/empty_state_asset_menu.svg';
import { FeatureFlagsManager } from '../../../../js/core/feature-flags-manager';
import { SubscriptionTopic } from '../../../../js/messaging/pubsub';
import { trimChars } from '../../../../js/utils/text/text';
import { AssetManager } from '../../../../js/viewer/asset-manager';
import { Comment } from '../../../../js/viewer/elements/comment';
import { Design } from '../../../../js/viewer/elements/design';
import { AssetWithFileInfo } from '../../../../js/viewer/elements/file-info/types';
import { Geometry } from '../../../../js/viewer/elements/geometry';
import { Model } from '../../../../js/viewer/elements/model';
import { Poi } from '../../../../js/viewer/elements/poi';
import { TerrainModel } from '../../../../js/viewer/elements/terrain-model';
import { useNonce } from '../../../hooks/use-nonce';
import { useSubscribe } from '../../../hooks/use-subscribe';
import { reset } from '../../../utils/styled-reset';
import { IconPanel } from '../../icon-panel/icon-panel';
import { AssetState } from '../state/asset-state';
import { CadGroupVisibilityState } from '../state/cad-group-visibility-state';
import { CategoryContext } from '../state/category-state';
import { CategoryVisibilityState } from '../state/category-visibility-state';
import { CheckBoxState } from '../state/checkbox-state';
import { AreaAsset } from './assets/area/area-asset';
import { CommentAsset } from './assets/comment/comment-asset';
import { DesignAsset } from './assets/design/design-asset';
import { LineAsset } from './assets/line/line-asset';
import { ModelAsset } from './assets/model/model-asset';
import { PoiAsset } from './assets/poi/poi-asset';
import { TerrainModelAsset } from './assets/terrain-model/terrain-model-asset';
import { CadGroup, CadGroupItem } from './components/asset-blocks/cad-group';
import { Category } from './components/asset-blocks/category';

type Props = {
  assetNameFilter: string;
};

const AssetMenuList = (props: Props) => {
  const { t } = useTranslation();
  const [refreshNonce, refresh] = useNonce();
  const [loadedOnce, setLoadedOnce] = React.useState(false);
  const categoryContext = React.useContext(CategoryContext);

  const categoryRefs = {
    design: React.createRef<HTMLDivElement>(),
    area: React.createRef<HTMLDivElement>(),
    line: React.createRef<HTMLDivElement>(),
    comment: React.createRef<HTMLDivElement>(),
    poi: React.createRef<HTMLDivElement>(),
    cad: React.createRef<HTMLDivElement>(),
    model: React.createRef<HTMLDivElement>(),
    terrainModel: React.createRef<HTMLDivElement>(),
  };
  const [designCategoryHeight, setDesignCategoryHeight] = React.useState(0);
  const [areaCategoryHeight, setAreaCategoryHeight] = React.useState(0);
  const [lineCategoryHeight, setLineCategoryHeight] = React.useState(0);
  const [commentCategoryHeight, setCommentCategoryHeight] = React.useState(0);
  const [poiCategoryHeight, setPoiCategoryHeight] = React.useState(0);
  const [cadCategoryHeight, setCadCategoryHeight] = React.useState(0);
  const [modelCategoryHeight, setModelCategoryHeight] = React.useState(0);
  const [terrainModelCategoryHeight, setTerrainModelCategoryHeight] = React.useState(0);

  const [designs, setDesigns] = React.useState<Design[]>([]);
  const [areas, setAreas] = React.useState<Geometry[]>([]);
  const [lines, setLines] = React.useState<Geometry[]>([]);
  const [comments, setComments] = React.useState<Comment[]>([]);
  const [pois, setPois] = React.useState<Poi[]>([]);
  const [cadFiles, setCadFiles] = React.useState<CadGroupItem[]>([]);
  const [models, setModels] = React.useState<Model[]>([]);
  const [terrainModels, setTerrainModels] = React.useState<TerrainModel[]>([]);

  useResizeObserver(categoryRefs.design, (entry) => {
    setDesignCategoryHeight(entry.borderBoxSize[0].blockSize);
  });
  useResizeObserver(categoryRefs.area, (entry) => {
    setAreaCategoryHeight(entry.borderBoxSize[0].blockSize);
  });
  useResizeObserver(categoryRefs.line, (entry) => {
    setLineCategoryHeight(entry.borderBoxSize[0].blockSize);
  });
  useResizeObserver(categoryRefs.comment, (entry) => {
    setCommentCategoryHeight(entry.borderBoxSize[0].blockSize);
  });
  useResizeObserver(categoryRefs.poi, (entry) => {
    setPoiCategoryHeight(entry.borderBoxSize[0].blockSize);
  });
  useResizeObserver(categoryRefs.cad, (entry) => {
    setCadCategoryHeight(entry.borderBoxSize[0].blockSize);
  });
  useResizeObserver(categoryRefs.model, (entry) => {
    setModelCategoryHeight(entry.borderBoxSize[0].blockSize);
  });
  useResizeObserver(categoryRefs.terrainModel, (entry) => {
    setTerrainModelCategoryHeight(entry.borderBoxSize[0].blockSize);
  });

  useSubscribe(SubscriptionTopic.AssetListChanged, refresh);

  React.useEffect(() => {
    const filterAndSort = <T extends { name: string }>(list: T[]) =>
      list
        .filter((x) => x.name.toLowerCase().includes(props.assetNameFilter.toLowerCase()))
        .sort((a, b) => a.name.localeCompare(b.name));

    setDesigns(filterAndSort(AssetManager.instance.getAssetsByType('design')));
    setAreas(filterAndSort(AssetManager.instance.getAssetsByType('area')));
    setLines(filterAndSort(AssetManager.instance.getAssetsByType('line')));
    setComments(filterAndSort(AssetManager.instance.getAssetsByType('comment')));
    setPois(filterAndSort(AssetManager.instance.getAssetsByType('poi')));
    setCadFiles(
      groupCadFiles(
        filterAndSort([
          ...(AssetManager.instance.getAssetsByType('dxf') as AssetWithFileInfo[]),
          ...(AssetManager.instance.getAssetsByType('glb') as AssetWithFileInfo[]),
        ]),
      ),
    );
    setModels(filterAndSort(AssetManager.instance.getAssetsByType('model')));
    setTerrainModels(filterAndSort(AssetManager.instance.getAssetsByType('terrain-model')));

    setLoadedOnce(true);
  }, [refreshNonce, categoryContext.refreshNonce, props.assetNameFilter]);

  const groupCadFiles = (cadFiles: AssetWithFileInfo[]) =>
    _.chain(cadFiles)
      .groupBy((asset) => {
        const folderId = asset.fileInfo?.folderId;
        const path = asset.fileInfo?.path.substring(0, asset.fileInfo.path.lastIndexOf('/'));

        // Group by folder id and path.
        // Returning object with these as properties isn't working.
        // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
        return `${folderId}#${path}`;
      })
      .map((value, key: string) => {
        const folderId = key.substr(0, key.indexOf('#'));
        const path = key.substr(key.indexOf('#') + 1);

        return {
          folderId,
          path: trimChars(path, ['/', ' ']),
          // @ts-ignore
          assets: _.sortBy(value, (asset) => asset.name.toLowerCase()),
        };
      })
      .sortBy((asset) => asset.path.toLowerCase())
      .value();

  const isPoiFlagActive = FeatureFlagsManager.instance.isEnabled('SK-6092-POI');
  const noAssets =
    designs.length === 0 &&
    areas.length === 0 &&
    lines.length === 0 &&
    (isPoiFlagActive ? pois.length : comments.length) === 0 &&
    cadFiles.length === 0 &&
    models.length === 0 &&
    terrainModels.length === 0;

  // TODO: Show spinner while fetching assets first time. Also add test for that scenario.
  // TODO: Not using loadedOnce will render empty state a short while before component gets
  // TODO: rerendered.
  if (loadedOnce && noAssets) {
    return (
      <Component>
        <NoContent>
          {props.assetNameFilter.length === 0 ? (
            <IconPanel header={<NoContentImage />} responsiveness={{ compressed: false }}>
              {{
                title: t('emptyState.noSearchText.title', { ns: 'skyviewAssetMenu' }),
                info: (
                  <Trans
                    components={{ bold: <strong /> }}
                    i18nKey="emptyState.noSearchText.info"
                    ns="skyviewAssetMenu"
                  />
                ),
              }}
            </IconPanel>
          ) : (
            <IconPanel header={<NoContentImage />} responsiveness={{ compressed: false }}>
              {{
                title: t('emptyState.searchText.title', { ns: 'skyviewAssetMenu' }),
                info: t('emptyState.searchText.info', { ns: 'skyviewAssetMenu' }),
              }}
            </IconPanel>
          )}
        </NoContent>
      </Component>
    );
  }

  return (
    <Component>
      {designs.length > 0 && (
        <CheckBoxState assets={designs} categoryType="design">
          <CategoryVisibilityState
            assets={designs}
            categoryType="design"
            height={designCategoryHeight}
          >
            <Category
              headerRef={categoryRefs.design}
              icon={{ icon: ['fal', 'pen-ruler'], fixedWidth: true }}
              title={t('design.categoryName', { ns: 'skyviewAssetMenu' })}
            >
              {designs.map((x) => (
                <AssetState key={`design-${x.uuid}`}>
                  <DesignAsset asset={x} />
                </AssetState>
              ))}
            </Category>
          </CategoryVisibilityState>
        </CheckBoxState>
      )}

      {areas.length > 0 && (
        <CheckBoxState assets={areas} categoryType="area">
          <CategoryVisibilityState assets={areas} categoryType="area" height={areaCategoryHeight}>
            <Category
              headerRef={categoryRefs.area}
              icon={{ icon: ['fal', 'draw-polygon'], fixedWidth: true }}
              title={t('area.categoryName', { ns: 'skyviewAssetMenu' })}
            >
              {areas.map((x) => (
                <AssetState key={`area-${x.uuid}`}>
                  <AreaAsset asset={x} />
                </AssetState>
              ))}
            </Category>
          </CategoryVisibilityState>
        </CheckBoxState>
      )}

      {lines.length > 0 && (
        <CheckBoxState assets={lines} categoryType="line">
          <CategoryVisibilityState assets={lines} categoryType="line" height={lineCategoryHeight}>
            <Category
              headerRef={categoryRefs.line}
              icon={{ icon: ['fal', 'wave-triangle'], fixedWidth: true }}
              title={t('line.categoryName', { ns: 'skyviewAssetMenu' })}
            >
              {lines.map((x) => (
                <AssetState key={`line-${x.uuid}`}>
                  <LineAsset asset={x} />
                </AssetState>
              ))}
            </Category>
          </CategoryVisibilityState>
        </CheckBoxState>
      )}

      {comments.length > 0 && !isPoiFlagActive && (
        <CheckBoxState assets={comments} categoryType="comment">
          <CategoryVisibilityState
            assets={comments}
            categoryType="comment"
            height={commentCategoryHeight}
          >
            <Category
              headerRef={categoryRefs.comment}
              icon={{ icon: ['fal', 'location-dot'], fixedWidth: true }}
              title={t('comment.categoryName', { ns: 'skyviewAssetMenu' })}
            >
              {comments.map((x) => (
                <AssetState key={`comment-${x.uuid}`}>
                  <CommentAsset asset={x} />
                </AssetState>
              ))}
            </Category>
          </CategoryVisibilityState>
        </CheckBoxState>
      )}

      {pois.length > 0 && isPoiFlagActive && (
        <CheckBoxState assets={pois} categoryType="poi">
          <CategoryVisibilityState assets={pois} categoryType="poi" height={poiCategoryHeight}>
            <Category
              headerRef={categoryRefs.poi}
              icon={{ icon: ['fal', 'location-dot'], fixedWidth: true }}
              title={'POI'}
            >
              {pois.map((x) => (
                <AssetState key={`poi-${x.uuid}`}>
                  <PoiAsset asset={x} />
                </AssetState>
              ))}
            </Category>
          </CategoryVisibilityState>
        </CheckBoxState>
      )}

      {cadFiles.length > 0 && (
        <CheckBoxState assets={cadFiles.flatMap((x) => x.assets)} categoryType="cad">
          <CategoryVisibilityState
            assets={cadFiles.flatMap((x) => x.assets)}
            categoryType="cad"
            height={cadCategoryHeight}
          >
            <Category
              headerRef={categoryRefs.cad}
              icon={{ icon: ['fal', 'cubes'], fixedWidth: true }}
              title={t('cad.categoryName', { ns: 'skyviewAssetMenu' })}
            >
              {cadFiles.map((x) => {
                return (
                  <CadGroupVisibilityState assets={x.assets} folderId={x.folderId} key={x.folderId}>
                    <CadGroup cadGroup={x} name={x.path} stickyTopPos={cadCategoryHeight} />
                  </CadGroupVisibilityState>
                );
              })}
            </Category>
          </CategoryVisibilityState>
        </CheckBoxState>
      )}

      {models.length > 0 && (
        <CheckBoxState assets={models} categoryType="model">
          <CategoryVisibilityState
            assets={models}
            categoryType="model"
            height={modelCategoryHeight}
          >
            <Category
              headerRef={categoryRefs.model}
              icon={{ icon: ['fal', 'cube'], fixedWidth: true }}
              title={t('model.categoryName', { ns: 'skyviewAssetMenu' })}
            >
              {models.map((x) => (
                <AssetState key={`model-${x.uuid}`}>
                  <ModelAsset asset={x} />
                </AssetState>
              ))}
            </Category>
          </CategoryVisibilityState>
        </CheckBoxState>
      )}

      {terrainModels.length > 0 && (
        <CheckBoxState assets={terrainModels} categoryType="terrain-model">
          <CategoryVisibilityState
            assets={terrainModels}
            categoryType="terrain-model"
            height={terrainModelCategoryHeight}
          >
            <Category
              headerRef={categoryRefs.terrainModel}
              icon={{ icon: ['fal', 'mountains'], fixedWidth: true }}
              title={t('terrainModel.categoryName', { ns: 'skyviewAssetMenu' })}
            >
              {terrainModels.map((x) => (
                <AssetState key={`terrain-model-${x.uuid}`}>
                  <TerrainModelAsset asset={x} />
                </AssetState>
              ))}
            </Category>
          </CategoryVisibilityState>
        </CheckBoxState>
      )}
    </Component>
  );
};

const Component = styled.div`
  ${reset}

  display: flex;
  flex-direction: column;
  gap: 1em;

  overflow: auto;
`;

const NoContent = styled.div`
  display: grid;
  place-content: center;
  height: 100%;
`;

AssetMenuList.styled = Component;

export { AssetMenuList, Props as AssetMenuListProps };
