import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import { GeodataModel } from '../../../../../typings/api/skymap/rest/v1/.common';
import { iiafe } from '../../../../../utilities/async';
import { parseGeodataStatus } from '../../../../js/components/gcp/gcp-lib';
//@ts-ignore
import { angularGlobals } from '../../../../js/core/config';
import { FeatureFlagsManager } from '../../../../js/core/feature-flags-manager';
import { SkyMapAxiosServiceFactory } from '../../../../js/services/axios/skymap-axios-service-factory';
import { UserStore } from '../../../../js/stores/user-store';
import { isDefined } from '../../../../js/utils/variables';
import { useDialog } from '../../../hooks/use-dialog';
import { Button, ButtonStyled } from '../../button/button';
import { Icon } from '../../icon/icon';
import { InfoBox } from '../../info-box/info-box';
import { Stack } from '../../stack/stack';
import { GcpAdjustmentDialog } from '../gcp-adjustment-dialog';
import { FilteredPointCloudDialog } from './filtered-point-cloud-dialog';
import { GeodataArtifacts } from './geodata-artifacts';
import { GeodataDetails } from './geodata-details';
import { SelectGeodata } from './select-geodata';
import { TerrainModelDialog } from './terrain-model-dialog';

interface Props {
  selectedItem?: GeodataModel;
  items: GeodataModel[];
}

export const goToGcpPage = async (geodata?: Pick<GeodataModel, 'id' | 'projectId'>) => {
  if (!geodata) {
    return;
  }

  await angularGlobals.getState().go('sky.project.details.geodata.setgcp', {
    projectId: geodata.projectId,
    geodataId: geodata.id,
  });
};

function hasFinished(geodata: GeodataModel) {
  return (
    geodata?.status === 'generate_skyview_data_finished' ||
    geodata?.status === 'generate_tif_finished' ||
    geodata?.status === 'generate_pointcloud_finished'
  );
}

function isReadyForGcpAdjustmentStep(geodata: GeodataModel) {
  return geodata?.status === 'user_move_flags';
}

function isIdle(geodata: GeodataModel) {
  return (
    isReadyForGcpAdjustmentStep(geodata) ||
    hasFinished(geodata) ||
    hasFailed(geodata) ||
    geodata?.status === 'created'
  );
}

function hasFailed(geodata: GeodataModel) {
  return geodata?.status?.toLowerCase().includes('failed');
}

function hasGcpAlignmentFinished(geodata: GeodataModel) {
  return !(
    geodata?.status === 'align_cameras' ||
    geodata?.status === 'align_photos_preparing' ||
    geodata?.status === 'match_photos' ||
    geodata?.status === 'optimize_cameras' ||
    geodata?.status === 'align_photos_failed' ||
    geodata?.status === 'align_photos_pending'
  );
}

function useGeodataResourceUrls(geodata?: GeodataModel) {
  const [resourceUrls, setResourceUrls] = useState(geodata?.resourceUrls);

  useEffect(() => {
    setResourceUrls(geodata?.resourceUrls);
    iiafe(async () => {
      if (
        FeatureFlagsManager.instance.isEnabled('SK-5825-cloudprocessing-download-project-zip') &&
        isDefined(geodata) &&
        hasGcpAlignmentFinished(geodata)
      ) {
        const response = await SkyMapAxiosServiceFactory.instance
          .createGeodataServiceV0()
          .fetchAgisoftProjectURL({
            path: {
              geodataId: geodata.id,
            },
          });

        setResourceUrls([
          ...(geodata.resourceUrls ?? []),
          {
            name: 'project',
            url: response.url,
          },
        ]);
      }
    });
  }, [geodata]);

  return { resourceUrls };
}

const GeodataListContent = (props: Props) => {
  const [gcpAdjustmentDialogVisible, setGcpAdjustmentDialogVisible] = React.useState(false);
  const terrainModelDialog = useDialog();
  const filteredPointCloudDialog = useDialog();
  const { resourceUrls } = useGeodataResourceUrls(props.selectedItem);

  const closeDialog = async (confirmed: boolean) => {
    setGcpAdjustmentDialogVisible(false);
    if (confirmed) {
      await goToGcpPage(props.selectedItem);
    }
  };

  if (!props.selectedItem) {
    return (
      <Component>
        <SelectGeodata />
      </Component>
    );
  }

  return (
    <Component>
      <Title>{props.selectedItem.name}</Title>
      <Stack direction="row" spacing={1}>
        {isReadyForGcpAdjustmentStep(props.selectedItem) && (
          <Button
            color="primary"
            variant="contained"
            onClick={async () => {
              if (props.selectedItem?.gcpAdjustmentRequest) {
                setGcpAdjustmentDialogVisible(true);
              } else {
                await goToGcpPage(props.selectedItem);
              }
            }}
          >
            Lägg till GCP
          </Button>
        )}
        {UserStore.instance.isOrganizationAdmin() && (
          <Button
            leftIcon={{ icon: ['fad', 'cog'] }}
            variant="contained"
            width="600px"
            onClick={() => terrainModelDialog.show()}
          >
            Markmodell
          </Button>
        )}
        {UserStore.instance.isOrganizationAdmin() && (
          <Button
            leftIcon={{ icon: ['fad', 'cog'] }}
            variant="contained"
            width="600px"
            onClick={() => filteredPointCloudDialog.show()}
          >
            Utglesat punktmoln
          </Button>
        )}
      </Stack>

      {!isIdle(props.selectedItem) && (
        <InfoBox
          bottomMargin={true}
          color="yellow"
          leftIcon={{ icon: ['fad', 'info'] }}
          topMargin={true}
          width="fit-content"
        >
          <Icon icon="spinner" spin={true} />
          <Text>{parseGeodataStatus(props.selectedItem?.status ?? 'Processerar')}</Text>
        </InfoBox>
      )}

      {hasFailed(props.selectedItem) && (
        <InfoBox
          bottomMargin={true}
          color="red"
          leftIcon={{ icon: ['fad', 'info'] }}
          topMargin={true}
          width="fit-content"
        >
          {parseGeodataStatus(props.selectedItem?.status)}
          <br />
          <br />
          {hasGcpAlignmentFinished(props.selectedItem) ? (
            <>Var vänlig kontakta vår support.</>
          ) : (
            <>
              Var vänlig kontrollera att foton samt eventuell gcp-fil och begränsningpolygon stämmer
              och är georefererade korrekt.
              <br />
              Om problemet kvarstår var vänlig kontakta vår support.
            </>
          )}
        </InfoBox>
      )}

      <Content>
        <GeodataDetails selectedItem={props.selectedItem} />
        <GeodataArtifacts resourceUrls={resourceUrls} selectedItem={props.selectedItem} />
      </Content>

      {gcpAdjustmentDialogVisible && <GcpAdjustmentDialog onClose={closeDialog} />}
      {terrainModelDialog.render(
        <TerrainModelDialog dialog={terrainModelDialog} geodata={props.selectedItem} />,
      )}
      {filteredPointCloudDialog.render(
        <FilteredPointCloudDialog dialog={filteredPointCloudDialog} geodata={props.selectedItem} />,
      )}
    </Component>
  );
};

const Component = styled.div`
  display: flex;
  flex-direction: column;
  background-color: white;
  height: 100%;
`;

const Title = styled.h1`
  font-weight: 500;
  font-size: 1.2em;
`;

const Text = styled.label`
  margin-left: 0.5em;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.25em;
  margin-top: 1em;
  width: 100%;

  ${ButtonStyled} {
    align-self: flex-start;
    margin-top: 1em;
  }
`;

export { GeodataListContent };
