import styled from 'styled-components';

export const TextLoading = styled.div`
  width: 70%;
  height: 0.8em;
  margin: 0.8em 0;
  background-color: ${(props) => props.theme.color.gray.lightest};
  border-radius: 8px;
  overflow: hidden;
  position: relative;

  ::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(
      90deg,
      transparent,
      ${(props) => props.theme.color.gray.light},
      transparent
    );
    animation: skeleton-loading 1.5s infinite;
  }

  @keyframes skeleton-loading {
    0% {
      transform: translateX(-100%);
    }
    100% {
      transform: translateX(100%);
    }
  }
`;
