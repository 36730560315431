import PropTypes from 'prop-types';
import React from 'react';
import styled, { useTheme } from 'styled-components';

import { formattedDateTime } from '../../../../js/utils/dateUtils';
import { isDefined } from '../../../../js/utils/variables';
import { useDialog } from '../../../hooks/use-dialog';
import { Button } from '../../button/button';
import { Icon } from '../../icon/icon';
import { Select } from '../../select/select';
import { DeleteSkyViewDataDialog } from './delete-skyview-data-dialog';
import { ModifiedTimelineContainer } from './manual-uploads';
import { NewUploadDialog } from './new-upload-dialog';

interface Props {
  title: string;
  accept: string;
  items: ManualUploadItem[];
  timelineContainers: ModifiedTimelineContainer[];
  showZUpCheckBox?: boolean;
  showCoordinateSystem?: boolean;
  showGeoids?: boolean;
  onUploaded: (params: ManualUploadParams) => Promise<ManualUploadItem>;
  onDelete: (id: ManualUploadItem) => Promise<void>;
}

export type ManualUploadItem = {
  id: string;
  name: string;
  uploaded: Date;
  status: string;
  isOldScan?: boolean;
};

export type ManualUploadParams = {
  uploadId: string;
  filename: string;
  isZUp?: boolean;
  coordinateSystemId?: string;
  geoidId?: string;
};

const SkyViewData = (props: Props) => {
  const theme = useTheme();
  const [items, setItems] = React.useState<ManualUploadItem[]>(props.items);
  const [selectedItem, setSelectedItem] = React.useState<ManualUploadItem | undefined>(
    props.items[0],
  );
  const deleteDialog = useDialog();
  const newUploadDialog = useDialog();

  return (
    <Component>
      <h5>{props.title}</h5>
      <Button
        color="primary"
        leftIcon={{ icon: ['fad', 'upload'] }}
        variant="contained"
        onClick={() => newUploadDialog.show()}
      >
        Ny uppladdning
      </Button>

      <p>
        <Select
          disabled={items.length === 0}
          options={items}
          placeholderText={'-- Inga uppladdningar --'}
          value={selectedItem?.id}
          onChange={(e) => {
            setSelectedItem(items.find((x) => x.id === e.target.value));
          }}
        />
        <Icon
          color={theme.color.gray.medium}
          data-testid="delete-icon"
          disabled={!isDefined(selectedItem)}
          icon={'trash-can'}
          marginLeft={'0.5em'}
          opacityhover={0.5}
          size={'lg'}
          onClick={() => {
            deleteDialog.show();
          }}
        />
      </p>

      <section>
        {selectedItem && (
          <>
            <label id="status">Status</label>
            <span aria-labelledby="status">{selectedItem.status}</span>
            <label id="uploaded">Uppladdad</label>
            <span aria-labelledby="uploaded">{formattedDateTime(selectedItem.uploaded)}</span>
          </>
        )}
      </section>

      {selectedItem &&
        deleteDialog.render(
          <DeleteSkyViewDataDialog
            item={selectedItem}
            timelineContainers={props.timelineContainers}
            onClose={(wasDeleted) => {
              deleteDialog.hide();
              if (wasDeleted) {
                const newItems = [...items];
                newItems.splice(items.indexOf(selectedItem), 1);

                setItems(newItems);
                setSelectedItem(newItems[0]);
              }
            }}
            onDelete={props.onDelete}
          />,
        )}

      {newUploadDialog.render(
        <NewUploadDialog
          accept={props.accept}
          showCoordinateSystem={props.showCoordinateSystem}
          showGeoids={props.showGeoids}
          showZUpCheckBox={props.showZUpCheckBox}
          title={props.title}
          onClose={(newItem) => {
            newUploadDialog.hide();
            if (newItem) {
              setItems([newItem, ...items]);
              setSelectedItem(newItem);
            }
          }}
          onUploaded={(params) => props.onUploaded(params)}
        />,
      )}
    </Component>
  );
};

const Component = styled.div`
  position: relative;
  background-color: white;
  box-shadow: 0px 0px 4px #a5acaf;
  padding: 16px;
  margin: 12px 8px;
  width: 100%;

  p {
    display: flex;
    align-items: center;
  }

  section {
    height: 8em;
    display: flex;
    flex-direction: column;
    gap: 0.5em;
    margin-top: 1em;

    label {
      font-weight: 600;
    }
  }

  button {
    height: 2em;
    margin-bottom: 0.5em;
  }
`;

SkyViewData.propTypes = {
  name: PropTypes.string,
};

export { SkyViewData };
