import React from 'react';
import styled from 'styled-components';

import { ProfileModel } from '../../../../typings/api/skymap/rest/v1/profile';
import { ProfileContext, ProfileLoadingState } from '../../state/profile-state';
import { Icon } from '../icon/icon';
import { TextLoading } from '../skeleton';
import { Stack } from '../stack/stack';

type Props = {
  profile?: ProfileModel;
};

const ProfileOverview = (props: Props) => {
  const { state } = React.useContext(ProfileContext);

  const isLoading = state === ProfileLoadingState.Loading;
  return (
    <Component direction="column" padding={'2em'} spacing={'1em'} {...props}>
      <Avatar icon={['fas', 'user-circle']} size="10x" />
      {isLoading ? <TextLoading /> : <Email>{props.profile?.email}</Email>}
    </Component>
  );
};

const Component = styled(Stack)`
  border-right: 1px solid ${(props) => props.theme.color.gray.dark};
`;

const Avatar = styled(Icon)``;

const Email = styled.h6`
  margin: 0;
  padding: 0;
`;

export { ProfileOverview };
