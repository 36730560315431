import React from 'react';
import styled from 'styled-components';

import { TextLoading } from '../skeleton';
import { Stack } from '../stack/stack';

type Props = {
  title: string;
  value: string | React.ReactNode | null;
  isLoading: boolean;
};

const PageProperty = (props: Props) => {
  return (
    <Component direction="column" {...props}>
      <Title>{props.title}</Title>

      {props.isLoading ? <TextLoading /> : <Value>{props.value}</Value>}
    </Component>
  );
};

const Component = styled(Stack)`
  width: 100%;
`;

const Title = styled.h6`
  color: ${(props) => props.theme.color.gray.medium};

  margin-bottom: 0;
`;

const Value = styled.h5`
  width: 100%;
  color: ${(props) => props.theme.color.gray.dark};
`;

export { PageProperty };
