import React from 'react';
import styled from 'styled-components';

import { Stack } from '../stack/stack';

type Props = {
  word: string;
};

const PageDivider = ({ word }: Props) => {
  return (
    <DividerContainer alignItems="center" direction="row">
      <Line />
      <Word>{word}</Word>
      <Line />
    </DividerContainer>
  );
};

const DividerContainer = styled(Stack)`
  margin: 20px 0;
  width: 100%;
`;

const Line = styled.hr`
  flex: 1;
  border: none;
  border-top: 1px solid ${(props) => props.theme.color.gray.medium};
  margin: 0;
`;

const Word = styled.span`
  margin: 0 10px;
  font-weight: bold;
  white-space: nowrap;
  color: ${(props) => props.theme.color.gray.medium};
`;

export { PageDivider };
